import { useSelector } from 'react-redux';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { queryKeys, setHeaders } from 'api/apiConfig';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { Tracking as TrackingApi } from 'generated/Tracking';
import { PageTrackingDataResponse, ProductTrackingDataResponse } from 'generated/data-contracts';
import { formatTranslation } from 'helpers/stringHelpers';
import { InitialState } from 'store/types';
import { useTranslationQuery } from './translations';

export const usePageTrackingQuery = (): UseQueryResult<PageTrackingDataResponse> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();

	return useQuery({
		queryKey: queryKeys.tracking.pageFetch(segmentationId).queryKey,
		queryFn: async (): Promise<PageTrackingDataResponse> => {
			const trackingApi = new TrackingApi({ baseApiParams: { headers: setHeaders() || undefined } });
			const response = await trackingApi.trackingGlobalpagedataList();

			if (!response.ok) {
				notificationActions.addNotification({
					children: formatTranslation(translations?.shared.layoutErrorMsg, {}),
					type: AlertTypes.DANGER,
				});
			}

			return response.data;
		},
	});
};

export const useProductTrackingQuery = (styleOptionNumber?: string): UseQueryResult<ProductTrackingDataResponse> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();

	return useQuery({
		queryKey: queryKeys.tracking.productFetch(segmentationId, styleOptionNumber).queryKey,
		queryFn: async (): Promise<ProductTrackingDataResponse | void> => {
			if (!styleOptionNumber) return;
			const trackingApi = new TrackingApi({ baseApiParams: { headers: setHeaders() || undefined } });
			const response = await trackingApi.trackingProducttrackingdataList({
				styleOptionNumber: styleOptionNumber,
			});

			if (!response.ok) {
				notificationActions.addNotification({
					children: formatTranslation(translations?.shared.layoutErrorMsg, {}),
					type: AlertTypes.DANGER,
				});
			}

			return response.data;
		},
		enabled: !!styleOptionNumber,
	});
};
