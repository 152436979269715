import React from 'react';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { useOrderLineContext } from '../state/useOrderLineContext';
import styles from './OrderLineHeader.module.scss';
import { OrderLineThumbnail } from './OrderLineThumbnail';
import { OrderMasterName } from './OrderMasterName';
import OrderProductDetails from './OrderProductDetails';

type OrderLineHeaderSubComponents = {
	Thumbnail: typeof OrderLineThumbnail;
	ProductDetails: typeof OrderProductDetails;
	MasterName: typeof OrderMasterName;
};

interface OrderLineHeaderProps {
	Thumbnail?: React.ReactNode;
	ProductDetails?: React.ReactNode;
}

export const OrderLineHeader: React.FunctionComponent<OrderLineHeaderProps> & OrderLineHeaderSubComponents = ({
	Thumbnail,
	ProductDetails,
}: OrderLineHeaderProps): JSX.Element => {
	const { size } = useOrderLineContext();
	return (
		<div className={classNames(styles.orderLineHeader, styles[`size${capitalize(size)}`])}>
			{Thumbnail}
			{ProductDetails}
		</div>
	);
};

OrderLineHeader.Thumbnail = OrderLineThumbnail;
OrderLineHeader.ProductDetails = OrderProductDetails;
OrderLineHeader.MasterName = OrderMasterName;