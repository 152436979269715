export const convertStyleOptionIdToMasterId = (styleOptionId: string): string => {
	if (styleOptionId.startsWith('M_')) {
		return styleOptionId;
	}

	return `M_${styleOptionId}`;
};
export const convertStyleIdToFamilyId = (styleId: string): string => {
	if (styleId.startsWith('F_')) {
		return styleId;
	}

	return `F_${styleId}`;
};

export const convertMasterIdToStyleOptionId = (styleOptionId: string): string => {
	return styleOptionId.replace('M_', '');
};

export const convertFamilyIdToStyleId = (styleId: string): string => {
	return styleId.replace('F_', '');
};
