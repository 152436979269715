export class NoProductsAvailableError extends Error {
	constructor() {
		super('No products available');
	}
}

export class CouldNotLoadProductsError extends Error {
	constructor() {
		super('Could not load products');
	}
}
