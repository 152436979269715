import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { Language as LanguageApi } from 'generated/Language';
import { ProblemDetails, RouteResponse, SetLanguageRequest } from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { formatTranslation } from 'helpers/stringHelpers';
import { setHeaders } from './apiConfig';
import { useTranslationQuery } from './translations';

export const useSetLanguageMutation = (): UseMutationResult<
	HttpResponse<RouteResponse, ProblemDetails | void>,
	HttpResponse<ProblemDetails | void>,
	SetLanguageRequest
> => {
	const { notificationActions } = useNotificationContext();
	const { data: translations } = useTranslationQuery();

	return useMutation({
		mutationFn: async (variables) => {
			const languageApi = new LanguageApi({ baseUrl: undefined, baseApiParams: { headers: setHeaders() } });
			return languageApi.languageSetCreate(variables);
		},
		onError: () => {
			notificationActions.addNotification({
				type: AlertTypes.DANGER,
				children: formatTranslation(translations?.shared.genericErrorMsg, {}),
			});
		},
	});
};
