import React, { FC } from 'react';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { LoadingBar } from 'components/shared/LoadingBar';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import styles from '../OrderMasterName.module.scss';

const OrderMasterNameSkeleton: FC = () => {
	const { size } = useOrderLineContext();
	return (
		<div className={classNames(styles.info, styles[`size${capitalize(size)}`])}>
			<div />
			<div className={styles.masterName}>
				<LoadingBar width="70%" />
			</div>
			<div />
		</div>
	);
};

export default OrderMasterNameSkeleton;
