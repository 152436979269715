import React from 'react';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { useMyOrdersListQuery } from 'api/myOrders';
import { InfiniteScrollTrigger } from 'components/shared/InfiniteScrollTrigger';
import { MyOrdersListOrderLine } from 'components/shared/OrderLine/MyOrdersListOrderLine';
import { skeletonOrderLines } from 'components/shared/OrderLine/helpers/skeletons';
import { GroupingRequest, OrderHistorySearchGroupingType } from 'generated/data-contracts';
import { OrderListUrlParamKeys } from '../../constants/OrderListUrlParamKeys';
import { useOrdersPageContext } from '../../context/OrdersPageContext';
import styles from './OrderLineList.module.scss';

interface OrderLineListProps {
	collapseGroups: GroupingRequest[];
}

export const OrderLineList: React.FC<OrderLineListProps> = ({ collapseGroups }) => {
	const { filters, size, status, currentSort } = useOrdersPageContext();

	const shipTo = collapseGroups?.find((group) => group.type === OrderHistorySearchGroupingType.ShipTo)?.value;
	const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage } = useMyOrdersListQuery({
		status,
		shipToIds: shipTo ? [shipTo] : [],
		phrase: filters?.searchPhrase,
		filters: [
			...(filters?.selectedFilters ?? []).filter((filter) => filter.filter !== OrderListUrlParamKeys.ShipTo),
		],
		collapseGroups,
		sortBy: currentSort?.sortBy,
		sortDirection: currentSort?.sortDirection,
	});

	const orderLines = React.useMemo(() => {
		if (!data) return [];
		return data.pages.flatMap((page) => page.orderLines);
	}, [data]);

	return (
		<ul className={classNames(styles.orderLineList, styles[`size${capitalize(size)}`])}>
			{orderLines?.map((orderLine, index) => (
				<React.Fragment key={`orderline-${index}-${orderLine.product?.styleNumber}`}>
					<li className={classNames(styles.listItem, styles[`size${capitalize(size)}`])}>
						<MyOrdersListOrderLine orderLine={orderLine} />
					</li>
					{index < orderLines.length - 1 && (
						<li className={styles.divider} aria-hidden>
							<hr className={'u-hr'} />
						</li>
					)}
				</React.Fragment>
			))}
			{(isFetching || isFetchingNextPage) &&
				skeletonOrderLines(1).map((sk, index) => (
					<li
						key={`orderline-skeleton-${index}`}
						className={classNames(styles.listItem, styles[`size${capitalize(size)}`])}
					>
						<MyOrdersListOrderLine isSkeleton orderLine={sk} />
					</li>
				))}

			<InfiniteScrollTrigger as="li" hasMore={hasNextPage === true} onLoadMore={fetchNextPage} />
		</ul>
	);
};
