import { FetchNextPageOptions, FetchPreviousPageOptions, InfiniteQueryObserverResult } from '@tanstack/react-query';
import { ProductListPageQuery } from 'api/search';
import { ProductCardProps } from 'components/shared';
import { PriceQueryProps } from 'components/shared/Filters/RangeFilter';
import { FilterRequest, NoPresentationBrandResult, ProductListResponse } from 'generated/data-contracts';

export const skeletonProductCards = (count = 8): ProductCardProps[] => {
	const arr: ProductCardProps[] = [];

	for (let i = 0; i < count; i++) {
		arr.push({
			isSkeleton: true,
		});
	}

	return arr;
};

export interface UseProductListActionsProps {
	hasPreviousPage: boolean | undefined;
	pageData: ProductListResponse | undefined;
	products: ProductCardProps[];
	hasNextPage: boolean | undefined;
	fetchNextPage: (
		options?: FetchNextPageOptions | undefined,
	) => Promise<InfiniteQueryObserverResult<ProductListResponse, void | NoPresentationBrandResult>>;
	fetchPreviousPage: (
		options?: FetchPreviousPageOptions | undefined,
	) => Promise<InfiniteQueryObserverResult<ProductListResponse, void | NoPresentationBrandResult>>;
	changeFilter: (id: string) => void;
	changeSorting: (sortBy: string, sortDirection: string) => Promise<void>;
	changePriceFilter: () => void;
	changeFilterMobile: (sortBy: string, sortDirection: string) => void;
	resetFilters: () => void;
	loading: boolean;
	toBeSet: FilterRequest[];
	toBeRemoved: FilterRequest[];
	setToBeSetFilters: React.Dispatch<React.SetStateAction<FilterRequest[]>>;
	setToBeRemovedFilters: React.Dispatch<React.SetStateAction<FilterRequest[]>>;
	priceFilter: PriceQueryProps;
	setPriceFilter: React.Dispatch<React.SetStateAction<PriceQueryProps>>;
	removeFilter: (id: string, value?: string) => void;
	updateNavParams?: (productIndex: number) => void;
	changeCategory?: (categoryId: string) => void;
	uniqueFilterId?: string;
	filterQuery?: ProductListPageQuery;
}
