import React, { FC } from 'react';
import { isEmpty } from 'lodash';
import { FavouriteIcon } from 'components/shared/FavouriteIcon';
import { SplashType } from 'generated/data-contracts';
import { Splash } from '../Splash';
import { useProductCardContext } from '../context/ProductCardContext';
import styles from './TopSplashes.module.scss';

export const TopSplashes: FC = () => {
	const {
		activeMaster,
		key,
		product: { id, favouriteListCases, splashes, isFavourited },
		showRemoveIcon,
		hideFavIcon,
		renderedOnFavouriteList,
	} = useProductCardContext();

	const favouriteListData = favouriteListCases?.[0];

	//Needs to be reworked whenever BE changes the structure of the response
	const isFavouritedMaster = !isEmpty(
		favouriteListCases?.find(
			(item) =>
				item.masterId === activeMaster?.id &&
				item.deliveryDate === activeMaster?.deliveryDate &&
				item.isFreeAssortment === activeMaster?.isFreeAssortment,
		),
	);

	return (
		<section className={styles.top}>
			<div className={styles.splashWrapper}>
				{splashes?.map(
					(splash, index) =>
						splash.type !== SplashType.FashionForward && (
							<Splash key={`ProductCardSplash-${key}-${index}`} splash={splash} />
						),
				)}
			</div>
			{!hideFavIcon && !!id && (
				<FavouriteIcon
					productId={id}
					className={styles.favIcon}
					deliveryMonth={
						renderedOnFavouriteList ? favouriteListData?.deliveryDate : activeMaster?.deliveryDate
					}
					productMasterId={renderedOnFavouriteList ? favouriteListData?.masterId : activeMaster?.id}
					isFreeAssortment={
						renderedOnFavouriteList ? favouriteListData?.isFreeAssortment : activeMaster?.isFreeAssortment
					}
					variantId={id}
					iconSize="md"
					buttonSize="sm"
					isFavourite={isFavourited ?? isFavouritedMaster}
					showRemoveIcon={showRemoveIcon}
				/>
			)}
		</section>
	);
};
