import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import CopyTextButton from 'components/shared/CopyTextButton';
import { LoadingBar } from 'components/shared/LoadingBar';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import styles from './OrderNumberAndBasketId.module.scss';

export interface OrderNumberAndBasketIdProps {
	copyable?: boolean;
	className?: string;
}

export const OrderNumberAndBasketId: FC = (props: OrderNumberAndBasketIdProps) => {
	const { className, copyable } = props;
	const { orderLineDetails, isSkeleton } = useOrderLineContext();
	const { data: translations } = useTranslationQuery();

	if (isSkeleton) {
		return (
			<div className={classNames(className, styles.valueCell)}>
				<div aria-hidden="true" />
				<LoadingBar height="1.5rem" />
			</div>
		);
	}

	if (!translations) return null;
	const basketId = copyable ? (
		<CopyTextButton
			copySuccessMessage={translations.myOrders.orderDetails.copiedBasketId}
			text={String(orderLineDetails.basketId)}
		/>
	) : null;

	return (
		<div className={classNames(className, styles.valueCell)}>
			<p
				title={translations?.myOrders.orderDetails?.orderNumberShort}
				className={classNames(styles.value, 'u-ellipsis')}
				aria-describedby={translations?.myOrders.orderDetails?.orderNumberShort}
			>
				{orderLineDetails.orderNumber}
			</p>
			{basketId ? (
				<div>
					<span>{translations.myOrders.orderDetails?.basketId}:</span>
					<span>{basketId}</span>
				</div>
			) : null}
		</div>
	);
};
