import React from 'react';
import { OrderLine } from '..';
import { OrderLineResponse } from 'generated/data-contracts';
import { Spacer } from '../OrderLineActions/Spacer';
import { OrderLineContextProvider } from '../state/useOrderLineContext';
import styles from './MyOrdersListOrderLine.module.scss';

interface OrderLineProps {
	orderLine: OrderLineResponse;

	isSkeleton?: boolean;
	size?: 'sm' | 'md' | 'lg';
	numberOfColumns?: number;
}

export const MyOrdersListOrderLine: React.FunctionComponent<OrderLineProps> = (props) => {
	return (
		<OrderLineContextProvider orderLine={props.isSkeleton ? undefined : props.orderLine} setSize={props.size}>
			<OrderLine
				className={styles.orderLine}
				numberOfColumns={props.numberOfColumns}
				Header={
					<OrderLine.Header
						Thumbnail={<OrderLine.Header.Thumbnail />}
						ProductDetails={<OrderLine.Header.ProductDetails />}
					/>
				}
				Details={
					<OrderLine.Details
						OrderDetailsLg={
							<>
								<OrderLine.Details.OrderNumber />
								<OrderLine.Details.EstimatedShippingDate />
								<OrderLine.Details.WarehouseShippingEta />
								<OrderLine.Details.Platform />
								<OrderLine.Details.Quantity />
								<OrderLine.Details.WholesalePriceNet />
								<OrderLine.Details.Value />
							</>
						}
						OrderDetailsSm={
							<>
								<OrderLine.Details.OrderNumber />
								<OrderLine.Details.EstimatedShippingDate />
								<hr className={'u-hr u-grid-col-span-2'} />
								<OrderLine.Details.Quantity />
								<OrderLine.Details.Value />
							</>
						}
						OrderDetailsMd={
							<>
								<OrderLine.Details.OrderNumber />
								<OrderLine.Details.EstimatedShippingDate />
								<OrderLine.Details.WarehouseShippingEta />
								<OrderLine.Details.Platform />
								<OrderLine.Details.Quantity />
								<OrderLine.Details.WholesalePriceNet />
								<OrderLine.Details.Value />
							</>
						}
					/>
				}
				Actions={
					<OrderLine.Actions
						ActionsLg={
							<>
								<OrderLine.Actions.ViewSizeSplit />
								<Spacer />
								<OrderLine.Actions.Invoices />
								<OrderLine.Actions.TrackAndTraces />
								<OrderLine.Actions.ViewOrder />
							</>
						}
						ActionsMd={
							<>
								<OrderLine.Actions.ViewSizeSplit />
								<OrderLine.Actions.Invoices />
								<OrderLine.Actions.TrackAndTraces />
								<OrderLine.Actions.ViewOrder />
							</>
						}
						ActionsSm={
							<>
								<OrderLine.Actions.ViewSizeSplit />
								<OrderLine.Actions.ViewOrder />
							</>
						}
					/>
				}
			/>
		</OrderLineContextProvider>
	);
};
