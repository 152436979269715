import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { UseInfiniteQueryResult, UseQueryResult, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { isEmpty, isEqual, omit } from 'lodash';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { Product as ProductApi } from 'generated/Product';
import {
	Area,
	FilterRequest,
	NoPresentationBrandResult,
	PageType,
	ProductCategoryId,
	ProductListResponse,
	QuickSearchResponse,
	SearchType,
} from 'generated/data-contracts';
import { isScannerApp } from 'helpers/app';
import { formatTranslation } from 'helpers/stringHelpers';
import { InitialState } from 'store/types';
import { queryKeys, setHeaders } from './apiConfig';
import { useTranslationQuery } from './translations';

export interface ProductListPageQuery {
	segmentationId?: number;
	phrase?: string;
	minPrice?: number;
	maxPrice?: number;
	page?: number;
	pageSize?: number;
	sortBy?: string;
	sortDirection?: string;
	productCategoryId?: string;
	filters?: FilterRequest[];
	excludedFilters?: FilterRequest[];
}

export const DefaultPageSize = 36;

export enum ProductListSearchParams {
	Phrase = 'phrase',
	Page = 'page',
	PageSize = 'pageSize',
	SortBy = 'sortBy',
	SortDirection = 'sortDirection',
	MinPrice = 'minPrice',
	MaxPrice = 'maxPrice',
}

const searchProducts = async (
	query: ProductListPageQuery,
	page: number,
	segmentationId: number,
): Promise<ProductListResponse> => {
	const productListApi = new ProductApi({
		baseApiParams: { headers: setHeaders() },
	});

	const result = await productListApi.productSearchCreate({
		segmentationId: segmentationId || 1,
		phrase: query.phrase,
		minPrice: query.minPrice,
		maxPrice: query.maxPrice,
		page: page || 1,
		pageSize: query.pageSize,
		sortBy: query.sortBy,
		sortDirection: query.sortDirection,
		productCategoryId: query.productCategoryId as ProductCategoryId | undefined,
		filters: query.filters,
		searchType: SearchType.Family,
	});

	return result.data;
};

export const parseQueryParameters = (
	productCategoryId: ProductCategoryId | undefined,
	searchParams: URLSearchParams,
): ProductListPageQuery => {
	const filters = [] as FilterRequest[];
	searchParams.forEach(function (value, key) {
		if (Object.values(ProductListSearchParams).includes(key as ProductListSearchParams)) return;
		filters.push({
			filter: key,
			value: value,
		});
	});

	return {
		productCategoryId: productCategoryId,
		phrase: searchParams.get(ProductListSearchParams.Phrase) || '',
		pageSize: Number(searchParams.get(ProductListSearchParams.PageSize)) || undefined,
		page: Number(searchParams.get(ProductListSearchParams.Page)) || 1,
		sortBy: searchParams.get(ProductListSearchParams.SortBy) || undefined,
		sortDirection: searchParams.get(ProductListSearchParams.SortDirection) || undefined,
		filters: filters,
		minPrice: Number(searchParams.get(ProductListSearchParams.MinPrice)) || undefined,
		maxPrice: Number(searchParams.get(ProductListSearchParams.MaxPrice)) || undefined,
	};
};

export const useProductSearchFilters = (
	productCategoryId?: ProductCategoryId,
): [(page?: number, pageSize?: number) => void, number | undefined] => {
	const [searchParams, setSearchParams] = useSearchParams();

	const currentPage = Number(searchParams.get(ProductListSearchParams.Page)) || 1;
	const route =
		searchParams.get('phrase') && !productCategoryId
			? { area: Area.StaticPages, pageType: PageType.Search }
			: {
					area: Area.ProductCategory,
					productCategoryId: productCategoryId,
			  };

	const setProductPage = (page?: number): void => {
		setSearchParams(
			(params) => {
				if (page) params.set(ProductListSearchParams.Page, page.toString());
				return params;
			},
			{ state: route, replace: true },
		);
	};

	return [setProductPage, currentPage];
};

export const useProductSearchQuery = (
	query: ProductListPageQuery,
): UseInfiniteQueryResult<ProductListResponse, void | NoPresentationBrandResult> => {
	const [setProductPage, currentPage] = useProductSearchFilters(query.productCategoryId);
	const [oldQuery, setOldQuery] = useState<ProductListPageQuery>(query);
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);

	return useInfiniteQuery({
		// currentPage should not be part of the key because react-query controls it internally
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: queryKeys.product.list(segmentationId, query).queryKey,
		queryFn: ({ pageParam = currentPage }) => {
			const page = isEqual(
				omit(query, [ProductListSearchParams.Page]),
				omit(oldQuery, [ProductListSearchParams.Page]),
			)
				? pageParam
				: 1;
			return searchProducts(query, page, segmentationId);
		},
		getNextPageParam: (lastPage) => {
			const numberOfPages = Math.ceil((lastPage.pagingInformation.totalNumberOfItems || 1) / DefaultPageSize);
			const page = lastPage.pagingInformation.currentPage + 1;
			const hasMorePages = page <= numberOfPages;
			return hasMorePages ? page : undefined;
		},
		getPreviousPageParam: (firstPage) => {
			const page = firstPage.pagingInformation.currentPage;
			return page > 1 ? page - 1 : undefined;
		},
		onSuccess: (data) => {
			const index = currentPage === data.pages.at(-1)?.pagingInformation.currentPage ? 0 : -1;
			const nextPage = data.pages.at(index)?.pagingInformation.currentPage || 1;
			setProductPage(nextPage);
			setOldQuery({
				...query,
				page: currentPage,
			});
		},
		retry: false,
		keepPreviousData: true,
		refetchOnWindowFocus: isScannerApp ? 'always' : true,
	});
};

export const useQuickSearch = (phrase): UseQueryResult<QuickSearchResponse, unknown> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();

	return useQuery({
		queryKey: queryKeys.product.quickSearch(segmentationId, phrase).queryKey,
		queryFn: async ({ signal }): Promise<QuickSearchResponse | void> => {
			const quickSearchApi = new ProductApi({ baseApiParams: { headers: setHeaders() } });
			const response = await quickSearchApi.productQuicksearchList({ segmentationId, phrase }, { signal });
			if (!response.ok) {
				notificationActions.addNotification({
					children: formatTranslation(translations?.shared.layoutErrorMsg, {}),
					type: AlertTypes.DANGER,
				});
			}

			return response.data;
		},
		retry: false,
		enabled: !isEmpty(phrase),
	});
};
