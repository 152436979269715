import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isEmpty, isEqual, uniq } from 'lodash';
import { useBasketQuery } from 'api/newBasket';
import { FilterResponse, FilterRequest, ActiveFilterResponse, Area, PageType } from 'generated/data-contracts';
import { useDebounce } from 'helpers/useDebounce';
import { OrderListUrlParamKeys } from '../constants/OrderListUrlParamKeys';

export interface UseFilterActionsResponse {
	activeFilters: ActiveFilterResponse[];
	changeFilter: (id?: string) => void;
	clearSearch: () => void;
	filters: FilterResponse[];
	removeFilter: (id: string, value?: string) => void;
	resetFilters: () => void;
	searchPhrase?: string;
	selectedFilters: FilterRequest[];
	setToBeRemovedFilters: React.Dispatch<React.SetStateAction<FilterRequest[]>>;
	setToBeSetFilters: React.Dispatch<React.SetStateAction<FilterRequest[]>>;
	toBeRemoved: FilterRequest[];
	toBeSet: FilterRequest[];
	updateSearch: (value?: string) => void;
	shouldRefetch: boolean;
	setShouldRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 *	Util function to be passed to a `.filter()` or `.some()` function.
 * 	Last argument can be used to keep or remove the item in the array if the filter matches
 *  */
export const filterList =
	(id?: string | null, value?: string | null, keepItemInArrayIfMatches: boolean = true) =>
	(filter: FilterRequest): boolean => {
		if (!id) return keepItemInArrayIfMatches;
		if (!value) return keepItemInArrayIfMatches ? filter.filter === id : filter.filter !== id;
		if (keepItemInArrayIfMatches) return filter.filter === id && filter.value === value;
		return filter.filter !== id || filter.value !== value;
	};

type FilterObject = {
	[key: string]: FilterRequest['value'][];
};

/**
 *	The filters work as follows
 *
 * 	1. selectedFilters are populated by the searchParams in the URL
 * 	2. API is fetched
 * 	3. searchParams are updated with the selectedFilters
 *
 * 	When a filter is selected, it is first added to the toBeSetFilters array, and when it is removed, it is added to the toBeRemovedFilters array.
 * 	These temporary filters will then update the `selectedFilters` array once `changeFilter` is executed
 *
 * */
export const useFilterActions = (
	filters: FilterResponse[],
	predefinedURLParameters: string[],
	filtersNotShownInActive: string[],
): UseFilterActionsResponse | undefined => {
	const state = React.useMemo(() => ({ area: Area.StaticPages, pageType: PageType.Orders }), []);
	const [searchParams, setSearchParams] = useSearchParams();
	const { data: basket } = useBasketQuery();
	const [hasBeenInitialized, setHasBeenInitialized] = React.useState<boolean>(false);

	const getInitiallySetFilters = React.useCallback(() => {
		const filtersInBase64 = searchParams.get(OrderListUrlParamKeys.Filters);
		let filterList: FilterRequest[] = [];
		try {
			const filterObject: FilterObject = filtersInBase64 ? JSON.parse(decodeURIComponent(filtersInBase64)) : [];
			filterList = Object.entries(filterObject).flatMap(([filter, values]) =>
				values.map((value) => ({
					filter,
					value,
				})),
			);
		} catch {
			// Filters object is borken. Delete it
			searchParams.delete(OrderListUrlParamKeys.Filters);
		}
		const shipToFiltersFromUrl = filterList.filter((filter) => filter.filter === OrderListUrlParamKeys.ShipTo);
		if (hasBeenInitialized || (!shipToFiltersFromUrl.length && !isEmpty(basket?.basketShipTos))) {
			filterList = filterList
				.filter((r) => r.filter !== OrderListUrlParamKeys.ShipTo)
				.concat(
					...(basket?.basketShipTos.map((shipTo) => ({
						filter: OrderListUrlParamKeys.ShipTo,
						value: shipTo,
					})) ?? []),
				);
		}
		if (basket?.basketShipTos?.length) {
			setHasBeenInitialized(true);
		}
		return filterList;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [basket?.basketShipTos]);

	const [selectedFilters, setSelectedFilters] = React.useState<FilterRequest[]>(getInitiallySetFilters);

	React.useEffect(() => {
		return () => {
			setHasBeenInitialized(false);
		};
	}, []);

	useEffect(() => {
		setSelectedFilters(getInitiallySetFilters);
	}, [getInitiallySetFilters]);
	const [shouldRefetch, setShouldRefetch] = React.useState<boolean>(false);
	const [toBeSet, setToBeSetFilters] = React.useState<FilterRequest[]>([]);
	const [toBeRemoved, setToBeRemovedFilters] = React.useState<FilterRequest[]>([]);
	const [searchPhrase, setSearchPhrase] = React.useState<string>(
		searchParams.get(OrderListUrlParamKeys.SearchPhrase)?.trim() ?? '',
	);

	const filtersInBase64 = React.useMemo(() => {
		const filterObject: FilterObject = selectedFilters.reduce<FilterObject>((acc, filter) => {
			if (!filter.filter) return acc;
			if (!acc[filter.filter]) acc[filter.filter] = [];
			acc[filter.filter].push(filter.value);
			return acc;
		}, {});
		const filtersInBase64 = encodeURIComponent(JSON.stringify(filterObject));
		return filtersInBase64;
	}, [selectedFilters]);
	useEffect(() => {
		setSearchParams(
			(prev) => {
				const newSearchParams = new URLSearchParams();
				prev.forEach((value, key) => {
					if (predefinedURLParameters.includes(key) && !newSearchParams.getAll(key).includes(value))
						newSearchParams.append(key, value);
				});
				newSearchParams.set(OrderListUrlParamKeys.Filters, filtersInBase64);

				if (prev.toString() === newSearchParams.toString()) return prev;
				return newSearchParams;
			},
			{
				replace: true,
				state,
			},
		);
	}, [filtersInBase64, filtersNotShownInActive, predefinedURLParameters, setSearchParams, state]);

	const updateSearchParam = React.useCallback(
		(value?: string) => {
			setSearchParams(
				(prev) => {
					if (value && value.length > 0) {
						prev.set(OrderListUrlParamKeys.SearchPhrase, value);
					} else {
						prev.delete(OrderListUrlParamKeys.SearchPhrase);
					}
					return prev;
				},
				{
					replace: true,
					state,
				},
			);
		},
		[setSearchParams, state],
	);

	const activeFilters = React.useMemo(() => {
		if (!filters) return [];
		return filters
			.filter((r): r is FilterResponse => !!r)
			.filter((filter) => !predefinedURLParameters.includes(filter.id))
			.filter((filter) => !filtersNotShownInActive.includes(filter.id))
			.flatMap((filter): ActiveFilterResponse[] =>
				(filter.values ?? [])
					.filter((r) => r?.isSelected)
					.map((fv) => ({
						id: filter.id,
						excluded: false,
						label: fv.label,
						value: fv.value,
					})),
			);
	}, [filters, filtersNotShownInActive, predefinedURLParameters]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedSearch = React.useCallback(useDebounce(updateSearchParam, 500), [updateSearchParam]);

	const updateSearch = (value?: string): void => {
		setSearchPhrase(value ?? '');
		debouncedSearch(value?.trim());
	};

	const clearSearch = (): void => {
		setSearchPhrase('');
		updateSearchParam(undefined);
	};

	const changeFilter = (id?: string): void => {
		const filterToBeSet = toBeSet.filter(filterList(id));
		const filterToBeRemoved = toBeRemoved.filter(filterList(id));
		setSelectedFilters((prev) => {
			const newFilters = uniq(
				(prev ?? [])
					.filter((filter) => !filterToBeRemoved.some(filterList(filter.filter, filter.value)))
					.concat(filterToBeSet),
			);
			if (isEqual(newFilters, prev)) return prev;
			return newFilters;
		});
		setToBeSetFilters((prev) => prev.filter(filterList(id, undefined, false)));
		setToBeRemovedFilters((prev) => prev.filter(filterList(id, undefined, false)));
		setShouldRefetch(true);
	};

	const removeFilter = (id: string, value?: string): void => {
		setSelectedFilters((prev) => {
			if (!prev) return [];
			return prev.filter(filterList(id, value, false));
		});
		setToBeSetFilters((prev) => prev.filter(filterList(id, value, false)));
		setToBeRemovedFilters((prev) => prev.filter(filterList(id, value, false)));
	};

	const resetFilters = (): void => {
		setSelectedFilters((prev) => {
			if (!prev) return [];
			return prev.filter((filter) => filter.filter && filtersNotShownInActive.includes(filter.filter));
		});

		setToBeSetFilters([]);
		setToBeRemovedFilters([]);
		setSearchParams(
			(prev) => {
				const newSearchParams = new URLSearchParams();
				prev.forEach((value, key) => {
					if (predefinedURLParameters.includes(key) && !newSearchParams.getAll(key).includes(value))
						newSearchParams.append(key, value);
				});
				if (prev.toString() === newSearchParams.toString()) return prev;
				return newSearchParams;
			},
			{
				replace: true,
				state,
			},
		);
	};

	return {
		activeFilters,
		changeFilter,
		clearSearch,
		filters: filters,
		removeFilter,
		resetFilters,
		searchPhrase,
		selectedFilters: selectedFilters ?? [],
		setToBeRemovedFilters,
		setToBeSetFilters,
		toBeRemoved,
		toBeSet,
		updateSearch,
		shouldRefetch,
		setShouldRefetch,
	};
};
