import { useSelector } from 'react-redux';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { Brand as BrandApi } from 'generated/Brand';
import {
	BrandListResponse,
	BrandResponse,
	PresentationBrandId,
	ProblemDetails,
	RouteResponse,
	SetBrandRequest,
} from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { formatTranslation } from 'helpers/stringHelpers';
import { InitialState } from 'store/types';
import { queryKeys, setHeaders } from './apiConfig';
import { useTranslationQuery } from './translations';

export const useBrandListQuery = (): UseQueryResult<BrandListResponse> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);

	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();

	return useQuery({
		queryKey: queryKeys.brand.all(segmentationId).queryKey,
		queryFn: async (): Promise<BrandListResponse> => {
			const brandApi = new BrandApi({ baseApiParams: { headers: setHeaders() } });
			const response = await brandApi.brandListList();

			if (!response.ok) {
				notificationActions.addNotification({
					type: AlertTypes.DANGER,
					children: formatTranslation(translations?.shared.genericErrorMsg, {}),
				});
			}

			return response.data;
		},
	});
};

export const useBrandGetByPresentationBrandQuery = (
	presentationBrandId: PresentationBrandId,
	isEnabled: boolean,
): UseQueryResult<BrandResponse, void> => {
	const segmentationId = useSelector((state: InitialState) => state.app.segmentationId);

	return useQuery({
		queryKey: queryKeys.brand.detail(segmentationId, presentationBrandId).queryKey,
		queryFn: async (): Promise<BrandResponse> => {
			const brandApi = new BrandApi({ baseApiParams: { headers: setHeaders() } });
			const response = await brandApi.brandGetbypresentationbrandList({ presentationBrandId });

			return response.data;
		},
		enabled: isEnabled,
	});
};

export const useSetBrandMutation = (): UseMutationResult<
	HttpResponse<RouteResponse, void | ProblemDetails>,
	HttpResponse<void>,
	SetBrandRequest
> => {
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();

	return useMutation({
		mutationFn: async (variables) => {
			const brandApi = new BrandApi({ baseUrl: undefined, baseApiParams: { headers: setHeaders() } });

			return brandApi.brandSetbrandCreate(variables);
		},
		onError: () => {
			notificationActions.addNotification({
				type: AlertTypes.DANGER,
				children: formatTranslation(translations?.shared.genericErrorMsg, {}),
			});
		},
	});
};
