import { createAction } from '@reduxjs/toolkit';
import { BrowserDeviceType } from 'generated/data-contracts';
import { AppTypes, OverlayTypes } from '../types';

const setShowOverlay = createAction<{ showOverlay: OverlayTypes | false }>(AppTypes.SET_SHOW_OVERLAY);

const updateSegmentation = createAction<number>(AppTypes.SEGMENTATION_UPDATE);

const setDevice = createAction<BrowserDeviceType>(AppTypes.SET_DEVICE);

export const appActions = {
	setShowOverlay,
	updateSegmentation,
	setDevice,
};
