import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import { IsDeliveredLabel } from '../../IsDeliveredLabel';
import { OrderDetailCell } from '../../OrderDetailCell';

export interface EstimatedShippingDateProps {
	hideIsDeliveredLabel?: boolean;
	className?: string;
}

export const EstimatedShippingDate: React.FunctionComponent<EstimatedShippingDateProps> = ({
	className,
	hideIsDeliveredLabel,
}) => {
	const { data: translations } = useTranslationQuery();
	const { orderLineDetails } = useOrderLineContext();
	return (
		<OrderDetailCell
			valueClassName={className}
			title={translations?.myOrders.orderDetails.estimatedShipping}
			value={orderLineDetails.estimatedShippingDate}
			label={!hideIsDeliveredLabel && orderLineDetails.isDelivered && <IsDeliveredLabel />}
		/>
	);
};
