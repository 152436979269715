/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  FavouriteListDetailsGetAllByGroupingRequest,
  FavouriteListDetailsGetAllRequest,
  PDFDownloadResponse,
  ProblemDetails,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class FavouriteListExport<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags FavouriteListExport
   * @name FavouritelistexportListaspdfCreate
   * @summary List products in favourite list to generate a PDF
   * @request POST:/api/favouritelistexport/listaspdf
   * @secure
   */
  favouritelistexportListaspdfCreate = (
    data: FavouriteListDetailsGetAllByGroupingRequest,
    params: RequestParams = {},
  ) =>
    this.request<PDFDownloadResponse, ProblemDetails | void>({
      path: `/api/favouritelistexport/listaspdf`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FavouriteListExport
   * @name FavouritelistexportExportasexcelCreate
   * @summary Gets the Excel file with all of detailed information about the products
   * @request POST:/api/favouritelistexport/exportasexcel
   * @secure
   */
  favouritelistexportExportasexcelCreate = (data: FavouriteListDetailsGetAllRequest, params: RequestParams = {}) =>
    this.request<File, ProblemDetails | void>({
      path: `/api/favouritelistexport/exportasexcel`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FavouriteListExport
   * @name FavouritelistexportExportasmediacontentCreate
   * @summary Gets a zip file containing an excel file and images related to the products
   * @request POST:/api/favouritelistexport/exportasmediacontent
   * @secure
   */
  favouritelistexportExportasmediacontentCreate = (
    data: FavouriteListDetailsGetAllRequest,
    params: RequestParams = {},
  ) =>
    this.request<File, ProblemDetails | void>({
      path: `/api/favouritelistexport/exportasmediacontent`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
