import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import { OrderDetailCell } from '../../OrderDetailCell';


export interface QuantityProps {
	className?: string;
	shortName?: boolean;
}

export const Quantity: React.FunctionComponent<QuantityProps> = ({ className, shortName = false }) => {
	const { orderLineDetails } = useOrderLineContext();
	const { data: translations } = useTranslationQuery();
	const label = shortName
		? translations?.myOrders.orderDetails?.quantityShortName
		: translations?.myOrders.orderDetails?.quantity;
	return <OrderDetailCell valueClassName={className} title={label} value={orderLineDetails.quantity} />;
};