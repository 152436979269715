import React from 'react';
import classNames from 'classnames';
import { icons } from 'assets/icons';
import { capitalize } from 'helpers/stringHelpers';
import styles from './Icon.module.scss';

export const iconNames = Object.keys(icons) as readonly IconName[];

export type IconName = keyof typeof icons;

export const iconSizes = ['2xs', 'xs', 'sm', 'md', 'lg', '16', 'xl'] as const;

export type IconSize = (typeof iconSizes)[number];

export interface IconProps {
	className?: string;
	svgClassName?: string;
	name: IconName;
	mobileSize?: IconSize;
	size: IconSize;
	rotate?: number;
	iconLabel?: string;
}

/**
 * Generic Icon component, "name" should be one of the icons' file name exported from assets/icons.
 */
export const Icon: React.FunctionComponent<IconProps> = ({
	className,
	svgClassName,
	name,
	size,
	mobileSize,
	rotate,
	iconLabel,
}) => {
	const iconId = icons[name];

	return (
		<i
			className={classNames(
				styles.icon,
				styles['size' + capitalize(size || 'md')],
				mobileSize && styles['mobileSize' + capitalize(mobileSize)],
				className,
			)}
			role="img"
			aria-label={iconLabel}
		>
			<svg
				className={classNames(svgClassName, styles.asset)}
				style={{ transform: rotate ? `rotate(${rotate}deg)` : undefined }}
			>
				<use xlinkHref={`#${iconId}`} />
			</svg>
		</i>
	);
};
