import { builder } from '@builder.io/react';
import { BuilderContent } from '@builder.io/sdk';
import { UserAttributes } from '@builder.io/sdk/dist/src/builder.class';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { queryKeys } from 'api/apiConfig';
import { AudienceTargetingResponse, BrowserDeviceType } from 'generated/data-contracts';

export const getDevice = (device: BrowserDeviceType): UserAttributes['device'] => {
	try {
		return device.toLowerCase() as UserAttributes['device'];
	} catch (error) {
		console.error(error);
		return 'desktop';
	}
};

const fetchContent = async (
	url: string,
	modelName: string,
	audience?: AudienceTargetingResponse,
): Promise<BuilderContent | null> => {
	const result = await builder
		.getContent(modelName, {
			url: url.toLowerCase() || '/',
			...(audience && {
				userAttributes: { ...audience, device: getDevice(audience.device) },
				options: { locale: audience.locale },
			}),
		})
		.promise();
	return result?.at(0) ?? null;
};

export const useCmsContentQuery = (
	url: string,
	modelName: string,
	audience?: AudienceTargetingResponse,
): UseQueryResult<BuilderContent | null> => {
	return useQuery({
		queryKey: queryKeys.cms.page(url, modelName, audience).queryKey,
		queryFn: () => fetchContent(url, modelName, audience),
		enabled: audience !== undefined,
	});
};

const menuItemLimit = 10;
const submenuItemCountLimit = 25;

export interface MenuItem {
	id: string | undefined;
	title: string;
	internalLink?: string;
	externalLink?: string;
	children?: MenuItem[];
}

const mapFooterMenus = (jsonData: BuilderContent[]): MenuItem[] => {
	const sortedItems = [...jsonData].sort((a, b) => {
		const orderA = a?.data?.order ?? 0;
		const orderB = b?.data?.order ?? 0;
		return orderA - orderB;
	});

	return sortedItems.map((item) => ({
		id: item.id,
		title: item?.data?.title,
		internalLink: item?.data?.internallink?.value?.data?.url,
		externalLink: item?.data?.externallink,
		children:
			item?.data?.items?.map((subItem) => ({
				id: subItem.id,
				title: subItem?.title,
				internalLink: subItem?.internallink?.value?.data?.url,
				externalLink: subItem?.externallink,
			})) ?? [],
	}));
};

const fetchCmsMenu = async (modelName: string, audience?: AudienceTargetingResponse): Promise<MenuItem[]> => {
	try {
		const options = {
			fields: 'id,data.title,data.order,data.internallink.value.data.url,data.externallink',
			limit: menuItemLimit,
			...(audience && {
				userAttributes: { ...audience, device: getDevice(audience.device) },
				options: { enrich: true, locale: audience.locale },
			}),
		};

		for (let i = 0; i < submenuItemCountLimit; i++) {
			options.fields =
				options.fields +
				`,data.items[${i}].title,data.items[${i}].internallink.value.data.url,data.items[${i}].externallink`;
		}

		const result = await builder.getAll(modelName, options);

		if (result?.length > 0) {
			return mapFooterMenus(result);
		}

		console.error(`No menu items found for locale ${audience?.locale} and model ${modelName}`, result);
		return [];
	} catch (error) {
		console.error(`Error fetching menu items for locale ${audience?.locale} and model ${modelName}`, error);
		return [];
	}
};

export const useCmsMenuQuery = (
	modelName: string,
	audience?: AudienceTargetingResponse,
): UseQueryResult<MenuItem[]> => {
	return useQuery({
		queryKey: queryKeys.cms.menu(modelName, audience).queryKey,
		queryFn: () => fetchCmsMenu(modelName, audience),
		enabled: audience !== undefined,
	});
};
