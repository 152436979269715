import React from 'react';
import classNames from 'classnames';
import { useViewportSize, useEventListener } from 'helpers';
import { useCmsMenuQuery } from 'api/cms';
import { useLayoutQuery } from 'api/layout';
import { PDFGenerationIndicator } from 'components/fragments/PDFGenerationIndicator';
import { Button, Drawer, DrawerBody, DrawerHeader, Icon } from 'components/shared';
import { TranslationsWebsite } from 'generated/data-contracts';
import CmsHeaderMenuLinks from '../CmsHeaderMenuLinks';
import styles from './CmsHeaderMenu.module.scss';

interface CmsHeaderMenuProps {
	translations?: TranslationsWebsite;
}

const CmsHeaderMenu: React.FunctionComponent<CmsHeaderMenuProps> = ({ translations }) => {
	const { isMobile } = useViewportSize();
	const [headerMenuIsOpen, setHeaderMenuIsOpen] = React.useState(false);
	const headerMenuRef = React.useRef<HTMLDivElement>(null);
	const [headerMenuOverflowing, setHeaderMenuOverflowing] = React.useState(false);
	const { data: layout } = useLayoutQuery();
	const { data: cmsMenu } = useCmsMenuQuery(layout?.cmsSettings.headerMenu ?? '', layout?.audienceTargeting);

	React.useEffect(() => {
		const headerMenuCurrent = headerMenuRef.current;

		if (headerMenuCurrent) {
			const isOverflowing = headerMenuCurrent.scrollHeight > headerMenuCurrent.clientHeight;
			setHeaderMenuOverflowing(isOverflowing);
		}
	}, []);

	const windowResize = React.useCallback(() => {
		const headerMenuCurrent = headerMenuRef.current;

		if (!headerMenuCurrent) return;

		const isOverflowing = headerMenuCurrent.scrollHeight > headerMenuCurrent.clientHeight;
		setHeaderMenuOverflowing(isOverflowing);
	}, []);

	useEventListener('resize', windowResize);

	const handleOpenHeaderMenu = (): void => {
		setHeaderMenuIsOpen(true);
	};

	const handleCloseHeaderMenu = (): void => {
		setHeaderMenuIsOpen(false);
	};

	return (
		<>
			{!isMobile && cmsMenu !== undefined && cmsMenu.length > 0 && (
				<section className={styles.headerMenuContainer}>
					<div className={classNames(styles.headerMenu)}>
						<div className={styles.headerMenuChildren} ref={headerMenuRef}>
							<CmsHeaderMenuLinks linkClassName={styles.headerMenuChild} />
						</div>
						{headerMenuOverflowing && (
							<>
								<Button
									hasNoStyles
									className={styles.headerMenuDrawerButton}
									onClick={handleOpenHeaderMenu}
								>
									{translations?.header.headerMenuMoreButtonText}
									<Icon name={'more'} size="sm" />
								</Button>
								{headerMenuIsOpen && (
									<Drawer onClose={handleCloseHeaderMenu} desktopWidth={'31.25rem'} direction="Right">
										<DrawerHeader
											title={translations?.shared.menuLabel}
											handleClose={handleCloseHeaderMenu}
										/>
										<DrawerBody>
											<div className={styles.headerMenuDrawer}>
												<CmsHeaderMenuLinks linkClassName={styles.headerMenuDrawerChild} />
											</div>
										</DrawerBody>
									</Drawer>
								)}
							</>
						)}
					</div>
					<PDFGenerationIndicator />
				</section>
			)}
		</>
	);
};

export default CmsHeaderMenu;
