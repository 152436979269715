import React from 'react';
import classNames from 'classnames';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import styles from './Alert.module.scss';

export const enum AlertTypes {
	SUCCESS = 'SUCCESS',
	WARNING = 'WARNING',
	DANGER = 'DANGER',
}

interface AlertProps {
	children: React.ReactNode;
	className?: string;
	contentClassName?: string;
	type: AlertTypes;
	onClose?: () => void;
}

/**
 * Alert component, used for showing a message to the user.
 * Appearance can be either success, warning or danger.
 */
export const Alert: React.FunctionComponent<AlertProps> = ({
	children,
	className,
	contentClassName,
	type,
	onClose,
}) => {
	return (
		<div
			className={classNames(className, styles.wrapper, {
				[styles.success]: type === AlertTypes.SUCCESS,
				[styles.warning]: type === AlertTypes.WARNING,
				[styles.danger]: type === AlertTypes.DANGER,
			})}
		>
			<Icon className={styles.icon} name={type === AlertTypes.SUCCESS ? 'check' : 'alert'} size={'md'} />

			<div className={classNames(styles.content, contentClassName)}>{children}</div>

			{onClose && (
				<Button hasOnlyIcon size="xs" variant="outline" onClick={onClose}>
					<Icon name={'close'} size="sm" />
				</Button>
			)}
		</div>
	);
};
