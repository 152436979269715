import account from './account.svg';
import alert from './alert.svg';
import apps from './apps.svg';
import arrowOut from './arrowOut.svg';
import back from './back.svg';
import backOrderWithDot from './backOrderWithDot.svg';
import backOrder from './backorder.svg';
import basket from './basket.svg';
import box from './basket/box.svg';
import calendar from './basket/calendar.svg';
import filledBasket from './basket/filledBasket.svg';
import hanger from './basket/hanger.svg';
import password from './basket/password.svg';
import ruler from './basket/ruler.svg';
import shipTo from './basket/shipTo.svg';
import basketFilled from './basketFilled.svg';
import bin from './bin.svg';
import boxDelivery from './boxDelivery.svg';
import burger from './burger.svg';
import category from './category.svg';
import check from './check.svg';
import checkCircle from './checkCircle.svg';
import checkMark from './checkMark.svg';
import checkroom from './checkroom.svg';
import chevronDown from './chevronDown.svg';
import chevronSolid from './chevronSolid.svg';
import close from './close.svg';
import copy from './copy.svg';
import curvedBasket from './curvedBasket.svg';
import curvedForwardArrow from './curvedForwardArrow.svg';
import defaultImage from './defaultImage.svg';
import dot from './dot.svg';
import download from './download.svg';
import drag from './drag.svg';
import driveFileRenameOutline from './driveFileRenameOutline.svg';
import edit from './edit.svg';
import error from './error.svg';
import external from './external.svg';
import fastForward from './fastForward.svg';
import favourite from './favorite.svg';
import favouriteFilled from './favouriteFilled.svg';
import favouritePlus from './favouritePlus.svg';
import filterList from './filterList.svg';
import filters from './filters.svg';
import forward from './forward.svg';
import info from './info.svg';
import key from './key.svg';
import DE from './language/DE.svg';
import DK from './language/DK.svg';
import ES from './language/ES.svg';
import FR from './language/FR.svg';
import GB from './language/GB.svg';
import GR from './language/GR.svg';
import IT from './language/IT.svg';
import NL from './language/NL.svg';
import NO from './language/NO.svg';
import SE from './language/SE.svg';
import TR from './language/TR.svg';
import logout from './logout.svg';
import minus from './minus.svg';
import more from './more.svg';
import plus from './plus.svg';
import receipt from './receipt.svg';
import scan from './scan.svg';
import search from './search.svg';
import share from './share.svg';
import shipment from './shipment.svg';
import socialFacebook from './social/socialFacebook.svg';
import socialInstagram from './social/socialInstagram.svg';
import stacks from './stacks.svg';
import storefront from './storefront.svg';
import success from './success.svg';
import sustainability from './sustainability.svg';
import truck from './truck.svg';
import user from './user.svg';
import userCircle from './userCircle.svg';
import uspClock from './usp/uspClock.svg';
import uspMoney from './usp/uspMoney.svg';
import uspPhone from './usp/uspPhone.svg';
import uspStar from './usp/uspStar.svg';
import uspTruck from './usp/uspTruck.svg';
import vmi from './vmi.svg';

export const icons = {
	account,
	alert,
	apps,
	arrowOut,
	back,
	backOrder,
	backOrderWithDot,
	basket,
	basketFilled,
	bin,
	box,
	boxDelivery,
	burger,
	category,
	calendar,
	curvedForwardArrow,
	curvedBasket,
	check,
	checkCircle,
	checkMark,
	checkroom,
	chevronDown,
	copy,
	defaultImage,
	edit,
	chevronSolid,
	shipment,
	close,
	drag,
	driveFileRenameOutline,
	DK,
	NL,
	GB,
	FR,
	DE,
	GR,
	IT,
	NO,
	ES,
	SE,
	TR,
	error,
	external,
	dot,
	download,
	fastForward,
	favourite,
	favouriteFilled,
	favouritePlus,
	filters,
	forward,
	filterList,
	filledBasket,
	hanger,
	password,
	key,
	logout,
	info,
	minus,
	more,
	plus,
	receipt,
	ruler,
	success,
	scan,
	search,
	share,
	shipTo,
	storefront,
	sustainability,
	stacks,
	truck,
	socialFacebook,
	socialInstagram,
	user,
	userCircle,
	uspClock,
	uspMoney,
	uspPhone,
	uspStar,
	uspTruck,
	vmi,
};
