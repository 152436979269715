import React from 'react';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { Icon, Link } from 'components/shared';
import { Area, FeatureFlag, PageType } from 'generated/data-contracts';
import styles from './MyInvoices.module.scss';

export const MyInvoices: React.FC = () => {
	const { data: layout } = useLayoutQuery();
	const { data: translations } = useTranslationQuery();

	const hasMyInvoicesFeatureFlag = layout?.userFeatureFlags.includes(FeatureFlag.MyInvoices);

	const myInvoiceUrl = layout?.staticLinks?.find((link) => link.pageType === PageType.Invoices)?.url;

	if (!myInvoiceUrl || !hasMyInvoicesFeatureFlag) return null;

	return (
		<Link
			to={myInvoiceUrl}
			route={{
				area: Area.StaticPages,
				pageType: PageType.Invoices,
				externalRoute: myInvoiceUrl,
			}}
			className={styles.myInvoices}
		>
			<div className={styles.label}>
				<Icon name="receipt" size={'md'} />
				<span className={styles.title}>{translations?.myInvoices.myInvoicesTitle}</span>
			</div>
			<Icon name={'chevronDown'} size={'lg'} rotate={270} />
		</Link>
	);
};
