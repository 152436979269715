import React from 'react';
import classNames from 'classnames';
import { Button } from 'components/shared/Button';
import { Icon } from 'components/shared/Icon';
import { RenderStates, useMounting } from 'components/shared/hooks/useMounting';
import styles from './Accordion.module.scss';

interface AccordionProps {
	title: string | React.ReactNode;
	id: string;
	isExpanded?: boolean;
	onToggle?: (isExpanded: boolean) => void;
	iconIsFlipped?: boolean;
	titleClassName?: string;
	titleButtonClassName?: string;
	children?: React.ReactNode;
	titleCustomButtons?: React.ReactNode;
	panelClassName?: string;
	contentWrapperClassName?: string;
}

/**
 * Generic Accordion component, used for displaying content in an accordion / collapse.
 */
export const AccordionV1: React.FunctionComponent<AccordionProps> = ({
	title,
	id,
	isExpanded,
	onToggle,
	iconIsFlipped,
	titleClassName,
	children,
	titleButtonClassName,
	panelClassName,
	contentWrapperClassName,
	titleCustomButtons,
}) => {
	const panelRef = React.useRef<HTMLDivElement>(null);
	const labelId = 'label' + id;

	const { UNMOUNTED } = RenderStates;

	const [expanded, setExpanded] = React.useState(isExpanded || false);
	const { status } = useMounting(expanded);

	const handleClick = (): void => {
		setExpanded(!expanded);

		if (!onToggle) return;
		onToggle(!expanded);
	};

	return (
		<>
			<h2 className={classNames(titleClassName, styles.titleWrapper)}>
				<Button
					className={classNames(titleButtonClassName, styles.titleButton, {
						[styles.isFlipped]: iconIsFlipped,
					})}
					hasNoStyles
					id={labelId}
					aria-expanded={expanded}
					onFocus={(e): void => e.stopPropagation()}
					aria-controls={id}
					onClick={handleClick}
				>
					<Icon
						className={styles.icon}
						name={'chevronDown'}
						size={'md'}
						rotate={expanded ? 180 : undefined}
					/>

					{typeof title === 'string' ? <span className={styles.title}>{title}</span> : <>{title}</>}
				</Button>
				{titleCustomButtons}
			</h2>
			<div
				className={classNames(styles.panel, panelClassName)}
				ref={panelRef}
				id={id}
				role={'region'}
				aria-labelledby={labelId}
				aria-hidden={!expanded}
			>
				{status !== UNMOUNTED && (
					<div className={classNames(styles.contentWrapper, contentWrapperClassName)}>{children}</div>
				)}
			</div>
		</>
	);
};
