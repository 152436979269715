import React, { FC } from 'react';
import { useTranslationQuery } from 'api/translations';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import { OrderDetailCell } from '../../OrderDetailCell';

export const CreatedBy: FC = () => {
	const { data: translations } = useTranslationQuery();
	const { orderLineDetails } = useOrderLineContext();
	return <OrderDetailCell title={translations?.myOrders.createdBy} value={orderLineDetails.createdBy} />;
};
