import { createReducer } from '@reduxjs/toolkit';
import { BrowserDeviceType } from 'generated/data-contracts';
import { appActions } from 'store/actions/appActions';
import { AppState } from 'store/types';

export const initialAppState: AppState = {
	segmentationId: 1,
	showOverlay: false,
	pageOrigin: '',
	device: BrowserDeviceType.Desktop,
};

const appReducer = createReducer(initialAppState, (builder) => {
	builder.addCase(appActions.setShowOverlay, (state, action) => {
		state.showOverlay = action.payload.showOverlay;
	});
	builder.addCase(appActions.updateSegmentation, (state, action) => {
		state.segmentationId = action.payload;
	});
	builder.addCase(appActions.setDevice, (state, action) => {
		state.device = action.payload;
	});
});

export default appReducer;
