import React from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { usePDFExportContext } from 'components/fragments/PDFExport/context/PDFExportContext';
import { Badge, Dropdown, Icon } from 'components/shared';
import styles from './PDFGenerationIndicator.module.scss';

export const PDFGenerationIndicator: React.FunctionComponent = () => {
	const { data: translations } = useTranslationQuery();
	const { generationJobsQueue } = usePDFExportContext();

	if (generationJobsQueue.length === 0) {
		return null;
	}

	return (
		<Dropdown
			contentClassName={styles.dropdownContent}
			buttonVariant="outline"
			hideArrow
			buttonSize="sm"
			buttonClassName={styles.button}
			buttonLabel={
				<>
					<Icon name="download" size="md" />
					<Badge className={styles.badge} value={generationJobsQueue.length} />
				</>
			}
		>
			<div className={styles.container}>
				<h4 className={styles.title}>{translations?.shared.exports.pdfQueueTitle}</h4>
				<p>{translations?.shared.exports.pdfQueueDescription}</p>

				<hr className={classNames('u-hr', styles.divider)} />

				<ul className={styles.list}>
					{generationJobsQueue.map((job) => (
						<li key={job.id} className={styles.indicator}>
							<span>{job.title}</span>
							{job.loading && <span className={classNames('u-spinner', styles.spinner)} />}
						</li>
					))}
				</ul>
			</div>
		</Dropdown>
	);
};
