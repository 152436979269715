import React from 'react';
import classNames from 'classnames';
import { capitalize } from 'helpers/stringHelpers';
import { useOrderLineContext } from '../state/useOrderLineContext';
import { GoToProductButton } from './GoToProduct';
import { InvoicesDropdown } from './InvoicesDropdown';
import styles from './OrderLineActions.module.scss';
import { Spacer } from './Spacer';
import { TrackOrdersDropdown } from './TrackOrdersDropdown';
import { ViewBasketButton } from './ViewBasketButton';
import { ViewOrderButton } from './ViewOrderButton';
import { ViewSizeSplitButton } from './ViewSizeSplitButton';

type OrderLineActionsSubComponents = {
	Invoices: typeof InvoicesDropdown;
	TrackAndTraces: typeof TrackOrdersDropdown;
	ViewBasket: typeof ViewBasketButton;
	ViewOrder: typeof ViewOrderButton;
	ViewSizeSplit: typeof ViewSizeSplitButton;
	Spacer: typeof Spacer;
	GoToProduct: typeof GoToProductButton;
};

export interface OrderLineActionsProps {
	ActionsLg: React.ReactNode;
	ActionsMd: React.ReactNode;
	ActionsSm: React.ReactNode;
}

export const OrderLineActions: React.FunctionComponent<OrderLineActionsProps> & OrderLineActionsSubComponents = ({
	ActionsLg,
	ActionsMd,
	ActionsSm,
}: OrderLineActionsProps): JSX.Element => {
	const { size } = useOrderLineContext();
	let Actions;
	switch (size) {
		case 'sm':
			Actions = ActionsSm;
			break;
		case 'md':
			Actions = ActionsMd;
			break;
		default:
			Actions = ActionsLg;
	}
	return <footer className={classNames(styles.orderLineFooter, styles[`size${capitalize(size)}`])}>{Actions}</footer>;
};

OrderLineActions.Invoices = InvoicesDropdown;
OrderLineActions.TrackAndTraces = TrackOrdersDropdown;
OrderLineActions.ViewOrder = ViewOrderButton;
OrderLineActions.GoToProduct = GoToProductButton;
OrderLineActions.ViewSizeSplit = ViewSizeSplitButton;
OrderLineActions.Spacer = Spacer;
OrderLineActions.ViewBasket = ViewBasketButton;
