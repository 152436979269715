import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { useListVmiShipToBrandsQuery, useListVmiShipTosQuery } from 'api/shipTo';
import { useTranslationQuery } from 'api/translations';
import { useVmiReportMutation } from 'api/vmi';
import { AlertTypes, Button, Dropdown, Icon } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { Brand, ShipToInfoResponse } from 'generated/data-contracts';
import { downloadFile } from 'helpers/downloadFile';
import styles from './VMI.module.scss';

interface VMIProps {
	handleClose: () => void;
}

export const VMI: React.FunctionComponent<VMIProps> = ({ handleClose }) => {
	const [shipTo, setShipTo] = useState<ShipToInfoResponse | undefined>(undefined);
	const [brand, setBrand] = useState<Brand | undefined>(undefined);

	const { data: translations } = useTranslationQuery();
	const { data: vmiShipTos } = useListVmiShipTosQuery();
	const { data: vmiShipToBrands } = useListVmiShipToBrandsQuery(shipTo?.id);
	const vmiReportMutation = useVmiReportMutation();

	const { notificationActions } = useNotificationContext();

	const handleOnClose = React.useCallback(() => {
		handleClose();
	}, [handleClose]);

	if (!translations) {
		return null;
	}

	const shipToLabel = shipTo?.name || translations.vmi.selectAccount;
	const brandLabel = brand?.name || translations.vmi.selectBrand;
	const downloadDisabled = vmiReportMutation.isLoading || !shipTo || !brand;
	const downloadLoading = vmiReportMutation.isLoading && !isEmpty(shipTo) && !isEmpty(brand);

	const handleOnClick = () => {
		vmiReportMutation.mutate(
			{ shipToId: shipTo?.id, brandId: brand?.id },
			{
				onSuccess: async ({ data }) => {
					await downloadFile(`${shipTo?.id}_${brand?.id}_vmi.pdf`, data);

					notificationActions.addNotification({
						children: translations?.vmi.downloadSuccess,
						type: AlertTypes.SUCCESS,
					});
				},
				onError: () => {
					notificationActions.addNotification({
						children: translations?.vmi.downloadError,
						type: AlertTypes.DANGER,
					});
				},
			},
		);
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<h2 className={styles.description}>{translations.vmi.description}</h2>

				<Dropdown
					buttonLabel={shipToLabel}
					buttonSize="md"
					buttonVariant="secondary"
					buttonClassName={styles.dropdownButton}
					contentClassName={styles.dropdownContent}
					unmountOnClose={false}
					contentFullWidth
					closeOnSelect
				>
					<div className={styles.options}>
						{vmiShipTos?.shipTos.map((shipTo) => (
							<Button
								key={shipTo.id}
								className={styles.option}
								onClick={() =>
									setShipTo(() => {
										setBrand(undefined);

										return shipTo;
									})
								}
								hasNoStyles
							>
								{shipTo.name}
							</Button>
						))}
					</div>
				</Dropdown>

				<Dropdown
					buttonLabel={brandLabel}
					buttonSize="md"
					buttonVariant="secondary"
					buttonClassName={styles.dropdownButton}
					contentClassName={styles.dropdownContent}
					unmountOnClose={false}
					disabled={!shipTo}
					contentFullWidth
					closeOnSelect
				>
					<div className={styles.options}>
						{vmiShipToBrands?.brands.map((brand) => (
							<Button
								key={brand.id}
								className={styles.smallerOption}
								onClick={() => setBrand(brand)}
								hasNoStyles
							>
								{brand.name}
							</Button>
						))}
					</div>
				</Dropdown>

				<Button
					variant="dark"
					size="md"
					type="button"
					onClick={handleOnClick}
					disabled={downloadDisabled}
					isLoading={downloadLoading}
				>
					{translations.vmi.download}
					<Icon name={'download'} size={'sm'} />
				</Button>
			</div>

			<Button variant="outline" size="md" className={styles.cancel} type="button" onClick={handleOnClose}>
				{translations?.shared.cancel}
			</Button>
		</div>
	);
};
