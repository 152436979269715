import { ProductBasket as ProductBasketApi } from 'generated/ProductBasket';
import { ProductBasketRequest, ProblemDetails, ProductBasketResponse } from 'generated/data-contracts';
import { HttpResponse } from 'generated/http-client';
import { setHeaders } from './apiConfig';

export const productbasketCreate = async (
	query: ProductBasketRequest,
	host?: string,
	cookies?: string,
): Promise<HttpResponse<ProductBasketResponse, ProblemDetails | void>> => {
	const productBasketApi = new ProductBasketApi({ baseUrl: host, baseApiParams: { headers: setHeaders(cookies) } });

	return productBasketApi.productbasketCreate({
		...query,
		basketDeliveryDate: query.basketDeliveryDate ?? null,
	});
};
