import React from 'react';
import classNames from 'classnames';
import { useNotificationContext } from '../Notifications/store/NotificationContext';
import { AlertTypes } from '../Alert';
import styles from './CopyTextButton.module.scss';

export interface CopyTextButtonProps {
	text?: string | number | null;
	title?: string;
	copySuccessMessage: React.ReactNode;
	className?: string;
}

const CopyTextButton: React.FunctionComponent<CopyTextButtonProps> = ({
	text,
	title,
	copySuccessMessage,
	className,
}) => {
	const { notificationActions } = useNotificationContext();
	const copyText = (e: React.MouseEvent): void => {
		e.stopPropagation();
		if (!text) return;
		navigator.clipboard.writeText(`${text}`);

		notificationActions.addNotification({
			children: copySuccessMessage,
			type: AlertTypes.SUCCESS,
		});
	};

	return (
		// Reason for this button being a span:
		// In the case that this is being used within a <button> element, this cannot be a button
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events
		<span
			role="button"
			onClick={copyText}
			tabIndex={0}
			className={classNames(className, styles.button)}
			title={title}
		>
			{text}
		</span>
	);
};

export default CopyTextButton;
