import React, { CSSProperties } from 'react';
import classNames from 'classnames';
import { capitalize } from 'helpers/stringHelpers';
import styles from './OrderLine.module.scss';
import { OrderLineActions } from './OrderLineActions';
import { OrderLineDetails } from './OrderLineDetails';
import { OrderLineHeader } from './OrderLineHeader';
import { useOrderLineContext } from './state/useOrderLineContext';

type OrderLineSubComponents = {
	Header: typeof OrderLineHeader;
	Actions: typeof OrderLineActions;
	Details: typeof OrderLineDetails;
};

export type OrderLineProps = {
	numberOfColumns?: number;
	className?: string;
	Header: React.ReactNode;
	Actions: React.ReactNode;
	Details: React.ReactNode;
};

export const OrderLine: React.FunctionComponent<OrderLineProps> & OrderLineSubComponents = ({
	numberOfColumns,
	className,
	Header,
	Actions,
	Details,
}: OrderLineProps) => {
	const { size } = useOrderLineContext();

	return (
		<article
			className={classNames(className, styles.orderLine, styles[`size${capitalize(size)}`])}
			{...(numberOfColumns && { style: { '--js-number-of-columns': numberOfColumns } as CSSProperties })}
		>
			<header className={classNames(styles.orderLineHeader, styles[`size${capitalize(size)}`])}>
				{Header}
				{size === 'lg' && Details}
			</header>
			{size !== 'lg' && Details}
			{Actions}
		</article>
	);
};

OrderLine.Header = OrderLineHeader;
OrderLine.Actions = OrderLineActions;
OrderLine.Details = OrderLineDetails;