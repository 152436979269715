import { useSearchParams } from 'react-router-dom';
import { useTranslationQuery } from 'api/translations';
import { OrderHistorySearchGroupingType, Area, PageType } from 'generated/data-contracts';
import { OrderListUrlParamKeys } from '../constants/OrderListUrlParamKeys';

interface Option {
	value: OrderHistorySearchGroupingType;
	displayName: string;
}

export const useGroupingFilter = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { data: translations } = useTranslationQuery();
	const groupingOptions: Option[] = translations
		? [
				{
					value: OrderHistorySearchGroupingType.ShipTo,
					displayName: translations.shared.grouping.orderLineGrouping,
				},
				{
					value: OrderHistorySearchGroupingType.DeliveryMonth,
					displayName: translations.shared.grouping.deliveryGrouping,
				},
				{
					value: OrderHistorySearchGroupingType.Category,
					displayName: translations.shared.grouping.categoryGrouping,
				},
		  ]
		: [];

	const currentGrouping =
		groupingOptions.find((r) => r.value === searchParams.get(OrderListUrlParamKeys.GroupedBy))?.value ??
		OrderHistorySearchGroupingType.ShipTo;

	const changeGrouping = (grouping: OrderHistorySearchGroupingType): void => {
		setSearchParams(
			(prev) => {
				prev.set(OrderListUrlParamKeys.GroupedBy, grouping);
				return prev;
			},
			{
				replace: true,
				state: { area: Area.StaticPages, pageType: PageType.Orders },
			},
		);
	};
	return { groupingOptions, currentGrouping, changeGrouping };
};
