import React from 'react';
import { useShiptoSearchQuery } from 'api/shipTo';
import { useTranslationQuery } from 'api/translations';
import { SearchableFilterType } from 'components/shared/Filters/SearchableFilter';
import { FilterValueResponse, FilterType } from 'generated/data-contracts';
import { useInputDebounce } from 'helpers/useInputDebounce';
import { OrderListUrlParamKeys } from '../constants/OrderListUrlParamKeys';


export const useAccountsFilter = (selectedShipTos: string[] = []): SearchableFilterType => {
	const { data: translations } = useTranslationQuery();

	const [accountsSearchInput, setAccountsSearchInput] = React.useState('');
	const debouncedAccountSearch = useInputDebounce(accountsSearchInput, 300);
	const shipToData = useShiptoSearchQuery({
		phrase: debouncedAccountSearch,
		pageSize: 36,
		selectedShipTos,
	});

	const allShipTos = React.useMemo((): FilterValueResponse[] => {
		return (
			shipToData.data?.pages.flatMap((r) => {
				return r.shipTos ?? [];
			}) ?? []
		).map((r) => {
			return {
				isSelected: selectedShipTos.some((s) => s == r.id),
				label: r.name,
				value: r.id,
			};
		});
	}, [selectedShipTos, shipToData.data?.pages]);

	const accountsFilter = React.useMemo((): SearchableFilterType => {
		return {
			name: translations?.myOrders.accounts ?? '',
			hasNextPage: false,
			search: {
				clearSearchText: () => setAccountsSearchInput(''),
				searchText: accountsSearchInput,
				setSearchText: setAccountsSearchInput,
			},
			id: OrderListUrlParamKeys.ShipTo,
			loadMore: shipToData.fetchNextPage,
			type: FilterType.String,
			values: allShipTos,
			renderInPortal: true,
		};
	}, [accountsSearchInput, allShipTos, shipToData.fetchNextPage, translations?.myOrders.accounts]);

	return accountsFilter;
};