import React from 'react';
import { nanoid } from 'nanoid';
import { Accordion as DSAccordion } from 'components/shared';

interface AccordionProps {
	title: string;
	children?: React.ReactNode;
	isDefaultExpanded?: boolean;
	attributes?: React.HTMLAttributes<HTMLDivElement>;
}

/**
 * CMS Accordion component, used for showing CMS content in an accordion
 */
export const Accordion: React.FC<AccordionProps> = ({ title, children, isDefaultExpanded }) => {
	return (
		<DSAccordion.V1 title={title} id={nanoid()} isExpanded={isDefaultExpanded}>
			{children}
		</DSAccordion.V1>
	);
};
