import { Builder, withChildren } from '@builder.io/react';
import { DAMImage } from '.';
import { isServer } from 'helpers/isServer';

const pageOrigin = isServer() ? '' : window.location.origin;

const DAMImageWithChildren = withChildren(DAMImage);

Builder.registerComponent(DAMImageWithChildren, {
	name: 'DamImage',
	image: pageOrigin + '/dist/images/cms/image.svg',
	noWrap: true,
	canHaveChildren: true,
	inputs: [
		{
			name: 'image',
			type: 'Bizzkit DAM File',
			required: true,
		},
		{
			name: 'altText',
			type: 'string',
		},
		{
			name: 'imageFit',
			type: 'string',
			defaultValue: 'cover',
			enum: [
				{
					label: 'contain',
					value: 'contain',
					helperText: 'The image should never get cropped',
				},
				{
					label: 'cover',
					value: 'cover',
					helperText: 'The image should fill its container, cropping when needed',
				},
			],
		},
		{
			name: 'fitContent',
			type: 'boolean',
			helperText: `When child blocks are provided, fit to them instead of using the image's aspect ratio`,
			defaultValue: false,
		},
		{
			name: 'aspectRatio',
			type: 'Bestseller Range Slider',
			helperText: `Height/width ratio, e.g. set to 1.5 for a 300px wide and 200px tall photo. Set to 0 for image
							to maintain its natural aspect ratio`,
			defaultValue: 0,
			min: 0,
			max: 5,
			step: 0.01,
			showIf: `options.get('fitContent') === false`,
		},
		{
			name: 'imagePosition',
			type: 'object',
			defaultValue: {
				x: 50,
				y: 50,
			},
			subFields: [
				{
					name: 'x',
					friendlyName: 'Horizontal',
					type: 'Bestseller Range Slider',
					helperText: `Horizontal position of the image within its container when cropped, e.g. set to 50 for
									center, 0 for left, 100 for right`,
					defaultValue: 50,
					min: 0,
					max: 100,
					step: 1,
				},
				{
					name: 'y',
					friendlyName: 'Vertical',
					type: 'Bestseller Range Slider',
					helperText: `Vertical position of the image within its container when cropped, e.g. set to 50 for
									center, 0 for top, 100 for bottom`,
					defaultValue: 50,
					min: 0,
					max: 100,
					step: 1,
				},
			],
		},
	],
});
