import React, { FC } from 'react';
import { OrderHistoryAccordionLevel } from '..';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { useTranslationQuery } from 'api/translations';
import { Icon } from 'components/shared';
import { useOrderHistoryViewportSize } from 'components/shared/hooks/useOrderHistoryViewportSize';
import { GroupingResponse } from 'generated/data-contracts';
import { useDebounce } from 'helpers/useDebounce';
import styles from './OrderHistoryAccordionSummary.module.scss';

export const createHeaderId = (level: OrderHistoryAccordionLevel, grouping: GroupingResponse) => {
	return `order-history-accordion-summary-${level}-${grouping.id}`;
};

export interface OrderHistoryAccordionSummaryProps {
	expanded: boolean;
	level: OrderHistoryAccordionLevel;
	grouping: GroupingResponse;
	onHover?: () => void;
}

export const OrderHistoryAccordionSummary: FC<OrderHistoryAccordionSummaryProps> = ({
	expanded,
	level,
	grouping,
	onHover = () => {},
}) => {
	const size = useOrderHistoryViewportSize();
	const { data: translations } = useTranslationQuery();
	const DropDownIcon = (
		<Icon
			name="chevronDown"
			size="sm"
			svgClassName={styles.arrowIcon}
			className={styles.icon}
			rotate={expanded ? undefined : -90}
		/>
	);

	const quantity = React.useMemo(() => {
		if (size === 'sm') {
			return `${translations?.myOrders.orderDetails.quantity}: ${grouping.totalQuantity}`;
		}
		return grouping.totalQuantity;
	}, [grouping.totalQuantity, size, translations]);

	const value = React.useMemo(() => {
		if (!grouping.totalValue) return;
		if (size === 'sm') {
			return `${translations?.myOrders.orderDetails.value}: ${grouping.totalValue}`;
		}
		return grouping.totalValue;
	}, [grouping.totalValue, size, translations]);

	const debouncedHover = useDebounce(onHover, 100);

	React.useEffect(() => {
		if (expanded) return;
		const summary = document.getElementById(createHeaderId(level, grouping));
		if (!summary) return;

		const mouseOver = () => {
			debouncedHover();
		};
		const mouseLeave = () => {
			debouncedHover.cancel();
		};
		summary.addEventListener('mouseover', mouseOver);
		summary.addEventListener('mouseleave', mouseLeave);
		return () => {
			summary.removeEventListener('mouseover', mouseOver);
			summary.removeEventListener('mouseover', mouseLeave);
		};
	}, [expanded, grouping, level, debouncedHover]);

	return (
		<div
			id={createHeaderId(level, grouping)}
			className={classNames(styles.titleRow, styles[`size${capitalize(size)}`], styles[`level${level}`], {
				[styles.indented]: level === OrderHistoryAccordionLevel.Second,
				[styles.expanded]: expanded,
			})}
		>
			{size === 'sm' && DropDownIcon}

			<div className={styles.title}>
				{size !== 'sm' && DropDownIcon}
				{level === OrderHistoryAccordionLevel.ShipTo && <Icon name="account" size="sm" />}
				{grouping.displayName}
			</div>
			<p className={styles.quantity}>{quantity}</p>
			<p className={styles.totalValue}>{value}</p>
		</div>
	);
};
