import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { queryKeys, setHeaders } from 'api/apiConfig';
import { AlertTypes } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import { Image } from 'generated/Image';
import { ImageLookupRequestItem, ImageLookupResponse } from 'generated/data-contracts';
import { formatTranslation } from 'helpers/stringHelpers';
import { useTranslationQuery } from './translations';

export const useImageLookup = (images: ImageLookupRequestItem[]): UseQueryResult<ImageLookupResponse> => {
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();

	return useQuery({
		queryKey: queryKeys.image.lookup(images).queryKey,
		queryFn: async (): Promise<ImageLookupResponse> => {
			const imageApi = new Image({ baseApiParams: { headers: setHeaders() || undefined } });
			const response = await imageApi.imageLookupCreate({ images });

			if (!response.ok) {
				notificationActions.addNotification({
					type: AlertTypes.DANGER,
					children: formatTranslation(translations?.shared.genericErrorMsg, {}),
				});
			}

			return response.data;
		},
	});
};
