import React from 'react';
import ReactDOM from 'react-dom';
import {
	autoUpdate,
	flip,
	offset,
	shift,
	size,
	hide,
	useClick,
	useDismiss,
	useFloating,
	useInteractions,
	useRole,
	FloatingFocusManager,
} from '@floating-ui/react';
import classNames from 'classnames';
import { Button, ButtonSizeType, ButtonVariantType } from 'components/shared/Button';
import { Icon, IconName } from 'components/shared/Icon';
import { portalRootId, useOnEscape } from 'helpers/index';
import styles from './Dropdown.module.scss';

export interface DropdownProps {
	className?: string;
	children?: React.ReactElement | React.ReactElement[];
	dropDownStatusChange?: (status: boolean) => void;
	forceClosed?: boolean;
	leadingIcon?: IconName;
	hideArrow?: boolean;
	buttonLabel: string | React.ReactElement;
	buttonVariant?: ButtonVariantType | 'hasNoStyles';
	buttonSize?: ButtonSizeType;
	buttonClassName?: string;
	contentClassName?: string;
	contentFullWidth?: boolean;
	alignContentRight?: boolean;
	isStatic?: boolean;
	disabled?: boolean;
	unmountOnClose?: boolean;
	closeOnSelect?: boolean;
	noInitialFocus?: boolean;
}

/**
 * Generic Dropdown component, used for adding dropdown behavior to any type of dropdown.
 * Dropdown content is passed as children. Used in e.g. FilterDropdown.
 */
export const Dropdown: React.FunctionComponent<DropdownProps> = ({
	className,
	forceClosed,
	dropDownStatusChange,
	buttonLabel,
	buttonVariant,
	buttonSize,
	buttonClassName,
	contentClassName,
	contentFullWidth,
	leadingIcon,
	hideArrow,
	alignContentRight,
	isStatic,
	disabled = false,
	unmountOnClose = true,
	closeOnSelect = false,
	noInitialFocus,
	children,
}) => {
	const wrapperRef = React.useRef<HTMLDivElement>(null);
	const [isOpen, setOpen] = React.useState(false);
	const { refs, floatingStyles, context } = useFloating({
		placement: 'bottom-start',
		open: isOpen,
		onOpenChange: setOpen,
		middleware: [
			offset(0),
			shift(),
			contentFullWidth &&
				size({
					apply({ rects, elements }) {
						Object.assign(elements.floating.style, {
							minWidth: `${rects.reference.width}px`,
						});
					},
				}),
			hide(),
			!isStatic ? flip() : null,
		],
		whileElementsMounted: autoUpdate,
		strategy: 'fixed',
	});
	const click = useClick(context, { toggle: true });
	const dismiss = useDismiss(context);
	const role = useRole(context, { role: 'dialog' });
	const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

	const handleClose = React.useCallback((): void => {
		setOpen(false);
	}, []);

	useOnEscape(handleClose, isOpen);

	React.useEffect(() => {
		if (forceClosed) {
			setOpen(false);
		}
	}, [forceClosed]);

	React.useEffect(() => {
		if (dropDownStatusChange) dropDownStatusChange(isOpen);
	}, [dropDownStatusChange, isOpen]);

	const handleOnFloatingClick = React.useCallback(() => {
		if (!closeOnSelect) {
			return;
		}

		setOpen(false);
	}, [closeOnSelect]);

	const portalRoot = document.getElementById(portalRootId) || document.body;

	const DropdownContent = React.useMemo(() => {
		return (
			<FloatingFocusManager context={context} initialFocus={noInitialFocus ? -1 : 1}>
				<div
					ref={refs.setFloating}
					style={floatingStyles}
					{...getFloatingProps({
						onClick: handleOnFloatingClick,
					})}
					className={classNames(
						styles.dropdownContent,
						{
							[styles.isRightAligned]: alignContentRight,
							[styles.isClosed]: !isOpen,
						},
						contentClassName,
					)}
				>
					{children}
				</div>
			</FloatingFocusManager>
		);
	}, [
		context,
		noInitialFocus,
		refs.setFloating,
		floatingStyles,
		getFloatingProps,
		handleOnFloatingClick,
		alignContentRight,
		isOpen,
		contentClassName,
		children,
	]);

	return (
		<div className={classNames(styles.wrapper, className)} aria-expanded={isOpen} ref={wrapperRef}>
			<Button
				innerRef={refs.setReference}
				{...getReferenceProps()}
				className={classNames(styles.button, buttonClassName)}
				size={buttonSize}
				disabled={disabled}
				{...(buttonVariant !== 'hasNoStyles' && { variant: buttonVariant })}
				hasNoStyles={buttonVariant === 'hasNoStyles'}
			>
				{leadingIcon && <Icon name={leadingIcon} size={'sm'} />}
				{typeof buttonLabel === 'string' ? (
					<span className={classNames(styles.label)}>{buttonLabel}</span>
				) : (
					<>{buttonLabel}</>
				)}
				{!hideArrow && <Icon name={'chevronDown'} size={'sm'} />}
			</Button>
			{unmountOnClose ? ReactDOM.createPortal(isOpen && DropdownContent, portalRoot) : DropdownContent}
		</div>
	);
};
