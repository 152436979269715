import {
	BasketDetailsResponse,
	BrowserDeviceType,
	ProductBasketRequest,
	ProductBasketResponse,
} from 'generated/data-contracts';

export enum AppTypes {
	SET_SEGMENTATION_ID = 'SET_SEGMENTATION_ID',
	SET_SHOW_OVERLAY = 'SET_SHOW_OVERLAY',

	ADD_NOTIFICATION = 'ADD_NOTIFICATION',
	REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
	CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS',
	SEGMENTATION_UPDATE = 'SEGMENTATION_UPDATE',

	SET_PAGE_ORIGIN = 'SET_PAGE_ORIGIN',

	SET_DEVICE = 'SET_DEVICE',
}

export enum BasketTypes {
	BASKET_UPDATE_CONTENT = 'BASKET_UPDATE_CONTENT',
	BASKET_UPDATE_PDP_CONTENT = 'BASKET_UPDATE_PDP_CONTENT',
	BASKET_UPDATE_PDP = 'BASKET_UPDATE_PDP',
	BASKET_UPDATE_BASKET_LINE = 'BASKET_UPDATE_BASKET_LINE',
	PRE_BASKET_UPDATE = 'PRE_BASKET_UPDATE',
	BASKET_UPDATE_MINI_QUANTITY = 'BASKET_UPDATE_MINI_QUANTITY',
	BASKET_CLEAR_CONTENT = 'BASKET_CLEAR_CONTENT',
}

export enum FavouriteTypes {
	FAVOURITE_SET_TOTAL_QUANTITY = 'FAVOURITE_SET_TOTAL_QUANTITY',
	FAVOURITE_LIST_SET_ID = 'FAVOURITE_LIST_SET_ID',
}

export enum TranslationsTypes {
	TRANSLATIONS_UPDATE_TRANSLATIONS = 'TRANSLATIONS_UPDATE_TRANSLATIONS',
}

export const enum OverlayTypes {
	CONTENT = 'CONTENT',
	FULL = 'FULL',
}

export interface AppState {
	segmentationId: number;
	showOverlay?: OverlayTypes | false;
	pageOrigin: string;
	device: BrowserDeviceType;
}

export interface BasketState {
	basket: BasketDetailsResponse | null;
	pdpBasket: ProductBasketResponse;
	preBasket: ProductBasketRequest;
	miniBasketQuantity: number;
}

export interface InitialState {
	basket: BasketState;
	app: AppState;
}
