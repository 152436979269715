import React from 'react';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { Icon, Link } from 'components/shared';
import { Area, PageType } from 'generated/data-contracts';
import styles from './MyOrders.module.scss';

export interface MyOrdersProps {}

const MyOrders: React.FunctionComponent<MyOrdersProps> = () => {
	const { data: layout } = useLayoutQuery();
	const { data: translations } = useTranslationQuery();
	const myOrdersListUrl = layout?.staticLinks?.find((link) => link.pageType === PageType.Orders)?.url;
	if (!myOrdersListUrl) return null;
	return (
		<Link
			to={myOrdersListUrl}
			route={{
				area: Area.StaticPages,
				pageType: PageType.Orders,
				externalRoute: myOrdersListUrl,
			}}
			className={styles.myOrders}
		>
			<div className={styles.label}>
				<Icon name="storefront" size={'md'} />
				<span className={styles.title}>{translations?.myOrders.myOrdersTitle}</span>
			</div>
			<Icon name={'chevronDown'} size={'lg'} rotate={270} />
		</Link>
	);
};

export default MyOrders;