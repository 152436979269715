import React, { useCallback, useEffect, useRef } from 'react';
import { To } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Button } from 'components/shared/Button';
import { Carousel } from 'components/shared/Carousel/Carousel';
import { useCarouselStore } from 'components/shared/Carousel/state/CarouselContext';
import { ProductImageColor } from 'components/shared/ColorDot';
import { Icon } from 'components/shared/Icon';
import { Link } from 'components/shared/Link';
import { Modal, UseModalReturnProps } from 'components/shared/Modal';
import { ProductImage } from 'components/shared/ProductImage';
import { ResourceLocation } from 'generated/data-contracts';
import { useViewportSize } from 'helpers/useViewportSize';
import styles from './GalleryModal.module.scss';

type GalleryModalProps = {
	modalProps: UseModalReturnProps;
	colorImages: Partial<ProductImageColor>[];
	modalTitle?: string;
	selectedImage?: number;
	to?: To;
	route?: ResourceLocation;
};

export const GalleryModal: React.FunctionComponent<GalleryModalProps> = ({
	modalProps,
	modalTitle,
	colorImages,
	to,
	route,
	selectedImage,
}) => {
	const { matchesSize } = useViewportSize();
	const isMobile = matchesSize(['xs']);

	const ulRef = useRef<HTMLUListElement>(null);

	const {
		slides: { activeSlide, setActiveSlide, setIntersectionEnabled, nextSlide, prevSlide },
	} = useCarouselStore();

	useEffect(() => {
		const refChildren = ulRef.current?.children;

		if (!refChildren) return;

		const element = Array.from(refChildren).find((child) => child.getAttribute('data-active') === 'true');

		element?.scrollIntoView({ behavior: 'smooth' });
	}, [activeSlide]);

	const onKeyDown = useCallback(
		(event: KeyboardEvent): void => {
			if (event.key === 'ArrowRight') {
				nextSlide();
			} else if (event.key === 'ArrowLeft') {
				prevSlide();
			}
		},
		[nextSlide, prevSlide],
	);
	useEffect(() => {
		// On press next or prev key, the carousel should show the correct image
		if (modalProps.rendered) {
			window.addEventListener('keydown', onKeyDown);
		} else {
			window.removeEventListener('keydown', onKeyDown);
		}
		return (): void => {
			window.removeEventListener('keydown', onKeyDown);
		};
	}, [modalProps.rendered, nextSlide, onKeyDown, prevSlide]);

	const { data: translations } = useTranslationQuery();

	const items = React.useMemo(
		() =>
			colorImages.map((image) => {
				return (
					<ProductImage
						key={`${image.size1800}`}
						canZoom
						className={classNames(styles.image, { [styles.defaultImage]: !image.size1800 })}
						desktopSrc={image.size1800}
						mobileSrc={image.size1800}
						altText={image?.altText || ''}
						colorInfo={image.color}
						hasColorDotText
						colorDotSize="thumbnail"
					/>
				);
			}),
		[colorImages],
	);

	return (
		<Modal {...modalProps} hideCloseButton size={'fullscreen'} className={styles.modal}>
			<div className={styles.modalHeader}>
				{isMobile ? (
					<h4>
						{activeSlide + 1} / {colorImages.length}
					</h4>
				) : (
					<span className={styles.modalTitleContainer}>
						<h4 className={styles.modalTitle}>{modalTitle}</h4>
						{to && (
							<Link className={styles.link} to={to} state={route} isTextLink hasExternalIconHidden>
								{translations?.productDetails.viewItem}
							</Link>
						)}
					</span>
				)}
				<Button
					hasOnlyIcon
					size="xs"
					variant="outline"
					className={styles.closeButton}
					onClick={(): void => {
						modalProps.close();
						// Make sure that the carousel shows the correct image when the modal is closed
						setIntersectionEnabled(false);
					}}
				>
					<Icon size="sm" name={'close'} />
				</Button>
			</div>
			<div className={styles.desktopWrapper}>
				<Carousel selectedImage={selectedImage} Badge={<Carousel.Badge />} Navigation={<Carousel.Navigation />}>
					{items}
				</Carousel>
				<div className={styles.thumbnailContainer}>
					<ul
						ref={ulRef}
						className={classNames(styles.thumbnails, 'u-scroll-wrapper-x', 'u-scroll-bar-hidden')}
					>
						{colorImages.map((image, index) => (
							<li key={`${image.size100}-${index}`} data-active={activeSlide === index}>
								<button
									className={styles.thumbnailCta}
									onClick={(): void => {
										if (activeSlide === index) return;
										setActiveSlide(index);
									}}
								>
									<ProductImage
										className={classNames(styles.thumbnail, {
											[styles.defaultImage]: !image?.size100,
										})}
										desktopSrc={image?.size100}
										mobileSrc={image?.size100}
										altText={image?.altText || ''}
										isThumbnail
										key={`galleryThumbnailImage-${index}`}
										colorInfo={image.color}
									/>
								</button>
							</li>
						))}
					</ul>
				</div>
			</div>
		</Modal>
	);
};
