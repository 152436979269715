import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import { OrderDetailCell } from '../../OrderDetailCell';

export const OrderDate: React.FunctionComponent = () => {
	const { data: translations } = useTranslationQuery();
	const { orderLineDetails } = useOrderLineContext();
	return (
		<OrderDetailCell title={translations?.myOrders.orderDetails.datePlaced} value={orderLineDetails.createdDate} />
	);
};
