import React, { FC } from 'react';
import classNames from 'classnames';
import { Accordion } from 'components/shared';
import { GroupingRequest, GroupingResponse, OrderHistorySearchGroupingType } from 'generated/data-contracts';
import styles from './OrderHistoryAccordion.module.scss';
import { OrderHistoryAccordionContent } from './OrderHistoryAccordionContent';
import { OrderHistoryAccordionSummary } from './OrderHistoryAccordionSummary';

export enum OrderHistoryAccordionLevel {
	ShipTo = 0,
	First = 1,
	Second = 2,
}

interface OrderHistoryAccordionProps {
	level: OrderHistoryAccordionLevel;
	type: OrderHistorySearchGroupingType;
	lastGroupings?: GroupingRequest[];
	grouping: GroupingResponse;
	expandedByDefault?: boolean;
}

export interface OrderHistoryAccordionSubComponents {
	Level: typeof OrderHistoryAccordionLevel;
}

export const OrderHistoryAccordion: FC<OrderHistoryAccordionProps> & OrderHistoryAccordionSubComponents = ({
	level,
	type,
	lastGroupings = [],
	grouping,
	expandedByDefault = false,
}: OrderHistoryAccordionProps) => {
	const [loadContent, setLoadContent] = React.useState(expandedByDefault);
	const [expanded, setExpanded] = React.useState(expandedByDefault);

	return (
		<div className={classNames(styles.accordionContainer)}>
			<Accordion.V2
				className={styles.accordion}
				expanded={expanded}
				renderContent={loadContent}
				onToggle={setExpanded}
				summaryClassName={classNames(styles.summary, styles[`level${level}`], {
					[styles.sticky]: expanded && level !== OrderHistoryAccordionLevel.Second,
					[styles.shipToGrouping]: level === OrderHistoryAccordionLevel.ShipTo,
				})}
				Summary={
					<OrderHistoryAccordionSummary
						expanded={expanded}
						level={level}
						grouping={grouping}
						onHover={() => setLoadContent(true)}
					/>
				}
			>
				<OrderHistoryAccordionContent
					className={styles.content}
					grouping={grouping}
					level={level}
					type={type}
					lastGroupings={lastGroupings}
				/>
			</Accordion.V2>
		</div>
	);
};

OrderHistoryAccordion.Level = OrderHistoryAccordionLevel;
