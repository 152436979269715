import React, { FC } from 'react';
import { useTranslationQuery } from 'api/translations';
import { useOrderLineContext } from 'components/shared/OrderLine/state/useOrderLineContext';
import { OrderDetailCell } from '../../OrderDetailCell';

export const BasketId: FC = () => {
	const { orderLineDetails } = useOrderLineContext();
	const { data: translations } = useTranslationQuery();
	return (
		<OrderDetailCell
			title={translations?.myOrders.orderDetails?.basketId}
			value={orderLineDetails.basketId ? String(orderLineDetails.basketId) : undefined}
		/>
	);
};
