import React from 'react';

/**
 * Capitalize first letter of a string
 *
 * @param string
 */
export const capitalize = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Strip all whitespace from a string
 * (from the whole string, not just start and end)
 *
 * @param string
 */
export const stripAllWhitespace = (string: string): string => string.replace(/\s/g, '');

/**
 * Replace bracket enclosed values of a string, with matching values from a provided object
 *
 * Usage:
 *
 * formatTranslation('Page {0} of {1}', { 0: 10, 1: 100 })
 *
 * @param translation
 * @param values
 */
export const formatTranslation = (translation: string | undefined, values: Record<string, unknown>): string => {
	const regex = /{(\w+)}/g;

	if (!translation) return '';

	return translation.replace(regex, (match, group) => {
		return String(values[group] ?? match);
	});
};

/**
 * Replace bracket enclosed values of a string, with matching values from a provided object
 *
 * Usage:
 *
 * formatTranslationAsJsx('Read our {0}', { 0: <a href="/terms">terms</a> })
 *
 * @param translation
 * @param values
 */
export const formatTranslationAsJsx = (
	translation: string | undefined,
	values: Record<string, React.ReactNode>,
): React.ReactNode => {
	const regexOuter = /({\w+})/;
	const regexInner = /{(\w+)}/;
	if (!translation) return '';
	return translation.split(regexOuter).map((item, i) => {
		const group = item.match(regexInner);
		const value = (group && values[group[1]]) || item;

		return <React.Fragment key={i}>{value}</React.Fragment>;
	});
};
