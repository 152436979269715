import React, { FC } from 'react';
import classNames from 'classnames';
import { formatTranslation, messageToApp } from 'helpers';
import { useSetLanguageMutation } from 'api/language';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { AlertTypes, Button, DrawerBody, Icon, IconName } from 'components/shared';
import { useNotificationContext } from 'components/shared/Notifications/store/NotificationContext';
import styles from './LanguageSelector.module.scss';

export const LanguageSelector: FC = () => {
	const { data: layout } = useLayoutQuery();

	const selectorSegmentations = layout?.segmentations;
	const { data: translations } = useTranslationQuery();
	const { notificationActions } = useNotificationContext();

	const setLanguage = useSetLanguageMutation();

	const handleNavigation = (languageId: number): void => {
		const body = {
			languageId,
			currentPath: location.pathname,
		};

		setLanguage.mutate(body, {
			onSuccess: (response) => {
				const redirectRoute = response.data.route.externalRoute || '/';

				messageToApp({ type: 'reloadWebView' });

				// Navigate with full page reload to re-initialize all the data in the store
				window.location.href = window.location.origin + redirectRoute;
			},
			onError: () => {
				notificationActions.addNotification({
					children: formatTranslation(translations?.shared.genericErrorMsg, {}),
					type: AlertTypes.DANGER,
				});
			},
		});
	};

	return (
		<DrawerBody className={styles.mainContent}>
			<ul>
				{selectorSegmentations?.map((item) => {
					const iconName = item.cultureCode?.split('-')[1]?.toUpperCase() as IconName;
					return (
						<li
							className={classNames(styles.menuItem, { [styles.activeItem]: item.isCurrentSegmentation })}
							key={item.name}
						>
							<Button
								hasNoStyles
								className={classNames(styles.menuLink, {
									[styles.isDisabled]: item.isCurrentSegmentation,
								})}
								disabled={item.isCurrentSegmentation}
								aria-current={item.isCurrentSegmentation ? 'page' : undefined}
								onClick={(): void => handleNavigation(item.languageId)}
							>
								<div className={styles.menuItemName}>
									<Icon className="u-mr-sm" name={iconName} size="sm" />
									<span>{item.name}</span>
								</div>
								{item.isCurrentSegmentation && <Icon name="checkMark" size="md" />}
							</Button>
						</li>
					);
				})}
			</ul>
		</DrawerBody>
	);
};
