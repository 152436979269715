import React from 'react';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { useTranslationQuery } from 'api/translations';
import { useOrderLineContext } from '../../state/useOrderLineContext';
import styles from './OrderMasterName.module.scss';
import OrderMasterNameSkeleton from './OrderMasterNameSkeleton';

export interface OrderMasterNameProps {
	masterNameClassName?: string;
}

export const OrderMasterName: React.FunctionComponent<OrderMasterNameProps> = ({ masterNameClassName }) => {
	const { isSkeleton, size, productInfo } = useOrderLineContext();
	const { data: translations } = useTranslationQuery();
	if (isSkeleton) return <OrderMasterNameSkeleton />;

	return (
		<div className={classNames(styles.info, styles[`size${capitalize(size)}`])}>
			<div />
			<p
				title={productInfo?.styleName}
				className={classNames(styles.masterName, masterNameClassName, 'u-ellipsis')}
			>
				{productInfo?.masters?.[0]?.name ?? translations?.myOrders.orderDetails.styleNotFound}
			</p>
			<div />
		</div>
	);
};
