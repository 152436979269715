import React, { useEffect, useRef, useCallback } from 'react';
import { convertRemToPx } from 'helpers/converPxToRem';

interface InfiniteScrollTriggerProps {
	onLoadMore?: () => void;
	hasMore: boolean;
	rootMargin?: `${number}rem`;
	as?: keyof JSX.IntrinsicElements;
}

/**
 * InfiniteScrollTrigger component triggers a callback function when the user scrolls to the bottom of the list.
 * It uses the Intersection Observer API to detect when the component is in view.
 *
 * @component
 * @param {Object} props - The properties object.
 * @param {() => void} props.onLoadMore - The callback function to be called when more items need to be loaded.
 * @param {boolean} props.hasMore - A boolean indicating if there are more items to load.
 * @param {string} [props.rootMargin='0rem'] - The root margin for the Intersection Observer. Defaults to '0rem'.
 * @param {keyof JSX.IntrinsicElements} [props.as='div'] - The HTML element to be used as the container. Defaults to 'div'.
 *
 * @example
 * <InfiniteScrollTrigger onLoadMore={loadMoreItems} hasMore={true} rootMargin="20rem" />
 *
 * @returns {JSX.Element} The InfiniteScrollTrigger component.
 */
export const InfiniteScrollTrigger: React.FC<InfiniteScrollTriggerProps> = ({
	onLoadMore,
	hasMore,
	rootMargin = '0rem',
	as = 'div',
}) => {
	const observer = useRef<IntersectionObserver | null>(null);
	const triggerRef = useRef<HTMLElement | null>(null);

	const handleObserver = useCallback(
		(entries: IntersectionObserverEntry[]) => {
			const target = entries[0];
			if (target.isIntersecting && hasMore) {
				onLoadMore?.();
			}
		},
		[onLoadMore, hasMore],
	);

	useEffect(() => {
		if (observer.current) observer.current.disconnect();

		observer.current = new IntersectionObserver(handleObserver, {
			rootMargin: convertRemToPx(rootMargin),
		});
		if (triggerRef.current) observer.current.observe(triggerRef.current);

		return () => {
			if (observer.current) observer.current.disconnect();
		};
	}, [handleObserver, rootMargin]);

	const Component = as as React.ElementType;

	return <Component ref={triggerRef as React.RefObject<HTMLElement>} />;
};
