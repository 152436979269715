import React, { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useLayoutQuery } from 'api/layout';
import { useTranslationQuery } from 'api/translations';
import { Button, Drawer, DrawerHeader, DrawerHeaderWBackButton, DrawerLevel, Icon } from 'components/shared';
import { AccountsView } from './AccountsView';
import styles from './ChangeOpenBasket.module.scss';

interface ChangeOpenBasketProps {
	handleClose?: () => void;
}

export const ChangeOpenBasket: FC<ChangeOpenBasketProps> = ({ handleClose }) => {
	const { data: layout } = useLayoutQuery();

	const { data: translations } = useTranslationQuery();
	const selectedBrandId = layout?.selectedBrandId;
	const [searchParams] = useSearchParams();

	const isOpenByDefault = searchParams.get('openBasket') === 'Desktop' && !isEmpty(selectedBrandId);
	const [isOpen, setIsOpen] = useState(isOpenByDefault);

	const handleBack = (): void => setIsOpen(false);

	const handleOpen = (): void => {
		setIsOpen(true);
	};

	return (
		<>
			<Button className={styles.openButton} hasNoStyles onClick={handleOpen}>
				<div className={styles.titleWrapper}>
					<Icon className={'u-mr-sm'} name={'account'} size={'md'} />
					<span className={styles.title}>{translations?.openBaskets.creatorButtonLabel}</span>
				</div>
				<Icon className={'u-ml-sm'} name={'chevronDown'} size={'lg'} rotate={270} />
			</Button>
			{isOpen &&
				(handleClose ? (
					<DrawerLevel isActive={isOpen}>
						<DrawerHeaderWBackButton
							handleBack={handleBack}
							handleClose={handleClose}
							title={translations?.openBaskets.creatorTitle}
						/>
						<AccountsView handleClose={handleClose} handleBasketSelectorBack={handleBack} />
					</DrawerLevel>
				) : (
					<Drawer onClose={handleBack}>
						<DrawerHeader handleClose={handleBack} title={translations?.openBaskets.creatorTitle} />
						<AccountsView handleClose={handleBack} handleBasketSelectorBack={handleBack} />
					</Drawer>
				))}
		</>
	);
};
