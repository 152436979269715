import React from 'react';

type DebouncedReturn<T> = T & { cancel: () => void };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = <T extends (...args: any) => any>(callback: T, delay: number): DebouncedReturn<T> => {
	// Use a ref to store the timeout between renders
	// and prevent changes to it from causing re-renders
	const timerRef = React.useRef<NodeJS.Timeout>();

	const debouncedCallback = React.useCallback(
		(...args: Parameters<T>) => {
			clearTimeout(timerRef.current as NodeJS.Timeout);

			timerRef.current = setTimeout(() => {
				callback(...args);
			}, delay);
		},
		[callback, delay],
	);

	(debouncedCallback as DebouncedReturn<T>).cancel = () => {
		clearTimeout(timerRef.current as NodeJS.Timeout);
	};

	return debouncedCallback as DebouncedReturn<T>;
};
