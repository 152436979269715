import React, { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import { useTranslationQuery } from 'api/translations';
import { Badge } from 'components/shared/Badge';
import { ButtonProps } from 'components/shared/Button';
import { Dropdown } from 'components/shared/Dropdown';
import { FilterRequest, FilterResponse, FilterType, PriceSliderResponse } from 'generated/data-contracts';
import { FilterContent } from '../FilterContent';
import { PriceQueryProps } from '../RangeFilter';
import styles from './FilterDropdown.module.scss';

export interface FilterDropdownProps extends FilterResponse {
	className?: string;
	onSubmit?: (id: string) => void;
	onPriceSubmit?: () => void;
	priceSliderValues?: PriceSliderResponse;
	activeCount?: number;
	toBeSet?: FilterRequest[];
	toBeRemoved?: FilterRequest[];
	setToBeSetFilters?: Dispatch<SetStateAction<FilterRequest[]>>;
	setToBeRemovedFilters?: Dispatch<SetStateAction<FilterRequest[]>>;
	priceFilter?: PriceQueryProps;
	setPriceFilter?: Dispatch<SetStateAction<PriceQueryProps>>;
	uniqueFilterId?: string;
	disabled?: boolean;
	buttonVariant: ButtonProps['variant'];
}

/**
 * FilterDropdown component, used for applying filters via checkboxes and range sliders
 */
export const FilterDropdown: React.FunctionComponent<FilterDropdownProps> = ({
	className,
	onSubmit,
	onPriceSubmit,
	type,
	id,
	name,
	values,
	min,
	max,
	selectedMin,
	selectedMax,
	priceSliderValues,
	activeCount,
	toBeSet,
	toBeRemoved,
	setToBeSetFilters,
	setToBeRemovedFilters,
	priceFilter,
	setPriceFilter,
	uniqueFilterId,
	disabled,
	buttonVariant,
}) => {
	const { data: translations } = useTranslationQuery();
	const formRef = React.useRef<HTMLFormElement>(null);
	const [forceClosed, setForceClosed] = React.useState(false);
	const hasChanged = priceSliderValues
		? priceFilter?.minPrice !== selectedMin || priceFilter?.maxPrice !== selectedMax
		: toBeSet?.some(
				(filter) =>
					filter.filter === id && values?.find((value) => value.value === filter.value)?.isSelected === false,
		  ) ||
		  toBeRemoved?.some(
				(filter) => filter.filter === id && values?.find((value) => value.value === filter.value)?.isSelected,
		  );

	React.useEffect(() => {
		setForceClosed(false);
	}, [toBeSet, toBeRemoved, priceFilter]);

	const handleSubmit = (e): void => {
		e.preventDefault();
		if (!onSubmit && !onPriceSubmit) return;

		setForceClosed(true);

		if (onSubmit) onSubmit(id);
		if (onPriceSubmit) onPriceSubmit();
	};

	const renderButtonLabel = (): React.ReactElement => (
		<>
			<span>{name}</span>
			<Badge value={activeCount} />
		</>
	);

	const handleDropdownChange = (state): void => {
		if (state || !hasChanged) return;

		if (onSubmit) onSubmit(id);
		if (onPriceSubmit) onPriceSubmit();
	};

	return (
		<Dropdown
			className={classNames(styles.dropdownWrapper, className)}
			contentClassName={classNames(styles.dropdownContent, {
				[styles.dropDownContainer]: type !== FilterType.Number,
			})}
			buttonClassName={styles.dropdownButton}
			forceClosed={forceClosed}
			dropDownStatusChange={handleDropdownChange}
			buttonVariant={buttonVariant}
			disabled={disabled}
			buttonSize={'sm'}
			isStatic
			buttonLabel={renderButtonLabel()}
		>
			<form name={`filterForm-${id}`} className={styles.form} onSubmit={handleSubmit} ref={formRef}>
				<fieldset className={styles.formFieldSet}>
					<legend className={styles.legend}>{name}</legend>
					<FilterContent
						type={priceSliderValues ? FilterType.Number : type}
						name={priceSliderValues && translations ? translations.productList.filters.price : name}
						id={priceSliderValues ? 'price' : id}
						min={priceSliderValues ? priceSliderValues?.minPrice : min}
						max={priceSliderValues ? priceSliderValues?.maxPrice : max}
						selectedMin={priceSliderValues ? priceSliderValues?.selectedMinPrice : selectedMin ?? undefined}
						selectedMax={priceSliderValues ? priceSliderValues?.selectedMaxPrice : selectedMax ?? undefined}
						values={priceSliderValues ? undefined : values}
						isPriceFilter={!!priceSliderValues}
						currencySymbol={priceSliderValues?.currencySymbol}
						toBeSet={toBeSet}
						toBeRemoved={toBeRemoved}
						setToBeSetFilters={setToBeSetFilters}
						setToBeRemovedFilters={setToBeRemovedFilters}
						priceFilter={priceFilter}
						setPriceFilter={setPriceFilter}
						hasChanged={hasChanged}
						uniqueFilterId={uniqueFilterId}
					/>
				</fieldset>
			</form>
		</Dropdown>
	);
};
