export const convertPxToRem = (px: number | `${number}px`, base: number = 16): `${number}rem` => {
	const pxValue = parseInt(String(px));
	const baseFontsize = base || parseFloat(getComputedStyle(document.documentElement).fontSize);

	return `${pxValue / baseFontsize}rem`;
};


export const convertRemToPx = (rem: number | `${number}rem`, base: number = 16): `${number}px` => {
	const remValue = parseFloat(String(rem));
	const baseFontsize = parseFloat(getComputedStyle(document.documentElement).fontSize) ?? base;
	return `${remValue * baseFontsize}px`;
};
